
import React from 'react';
import '../../styles/subsection.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';

function Structure() {
    return (
        <div className="subsubsection-container">
            <Header />
            <div class="side-nav-container">
                <aside className="subsubsection-side-nav">
                    <a href="#purpose">Purpose</a>
                    <a href="#whatyoulearn">Content</a>
                    <a href="#howyoustudy">Learning</a>
                </aside>
            </div>
            
            <main className="subsubsection-content">
                <div className="titles"><h1>Website Structure</h1></div>
                <section id="purpose"> 
                    <h2>Purpose</h2>
                    <p className="subsubsection-paragraph">The explicit purpose of this website is to provide information about ~all NLP models that I could think to write about. 
                    I use "all" loosely of course but
                    my intention is that you walk away from this website with some mildly decent level of understanding about the intuition underpinning many NLP algorithms. I will do my best to make this information
                    as digestible as possible but, fair warning: this is not meant to be a textbook level recreation of explanations; it may serve better as a reference or a way to 
                    clear up any misunderstandings in such a way that attempts to minimize any jargon-y gatekeeping. Also, there will probably be lots of typos; if you see any, feel free 
                    to reach out so that I can correct them. I also welcome any messages about general comments/considerations/other mistakes. You can find my Linkedin <a href="https://www.linkedin.com/in/b-thind/?originalSubdomain=ca">here.</a>  </p>
                </section>

                <section id="whatyoulearn">
                    <h2>What you will learn</h2>
                    <p className="subsubsection-paragraph">I will cover what I can. Anything that is not covered is likely a pre-requisite and something you should know before coming
                    into the content. Here's a breakdown of what you can expect to learn:
                    
                    <ul>
                        <li><strong>Begin:</strong> You just went through this so you already know.</li>
                        <li><strong>Foundations:</strong> I will tell you what you should know before diving into the rest of the content on here. I won't be going into too much details on some 
                        of these topics as they are all pre-requisites but you can use what's here as a quick reference or reminder.</li>
                        <li><strong>NLP Basics:</strong> This will cover concepts and pre-processing you should know with respect to NLP. I will also talk about basics methods and evaluation approaches.</li>
                        <li><strong>Machine Learning:</strong> The bulk of what you need to know -- covering, in-depth-, a wide array of classic + moden NLP approaches.</li>
                        <li><strong>Large Language Models:</strong> This section will cover the specifics of LLMs including how they are trained, fine-tuning, distillation, and ethics.</li>
                        <li><strong>Current Standard:</strong> Details on current language models that are widely used -- information about what is happening in each.</li>
                        <li><strong>Frontier:</strong> The future of NLP, kind-of. I only touch the surface of the models in this section.</li>
                    </ul>
                    
                     </p>

                     <p className="subsubsection-paragraph">As with all things, how much you get out of this is entirely dependant on you.</p>
                </section>

                <section id="howyoustudy">
                    <h2>How you should work through this website</h2>
                    <p className="subsubsection-paragraph">Within each section, I will go over all relevant technical details; this will be followed by a list of hyperparameters of the models. 
                    Then I will give you coding examples in Python -- you can run the code yourself in whatever your favorite IDE (VS Code) is. Also,
                     at the beginning of each section, I will provide
                    a small table that will give names of relevant papers, tasks, efficiency etc. associated with the model being discussed. Also, not all of the code runs right away. Some of it 
                    may require you to define paths to specific datasets, etc. The code is meant just as a starting point. </p>

                    <p className="subsubsection-paragraph">Ideally, I think what would be best is if you, after reading through, understanding, and running all the code, create your own models
                    with your own datasets. This is the best way to learn and will also help you build a portfolio (if you needed to do that). 
                    I will also probably randomly throw in questions here and there that you should do that I will probably not provide solutions for but you can always ask ChatGPT. The last thing 
                    I'll note is that I used tons of resources to compile this website including ChatGPT, various youtubers, blog posts, etc. There's a lot out there and for more details on some of 
                    these topics, you are probably better off watching videos or reading papers, etc. Again, this website is to be used as a reference and it's something I made publicly available so 
                    that it can be used as a reference. </p>
                </section>
                
                
                <div className="subsubsection-navigation">
                    <Link to="/begin/history">← History</Link>
                    <Link to="/foundations">Foundations →</Link>
                </div>
            </main>
            
            <Footer />
        </div>
    );
}

export default Structure;
