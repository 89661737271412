
import React from 'react';
import '../../styles/subsection.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';

function WhatIsIt() {
    return (
        <div className="subsubsection-container">
            <Header />
            <div class="side-nav-container">
                <aside className="subsubsection-side-nav">
                    <a href="#intro">What is NLP?</a>
                </aside>
            </div>
            
            <main className="subsubsection-content">
            <div className="titles"><h1>What Is It?</h1></div>
                <section id="intro">
                    
                    <p className="subsubsection-paragraph">If you're on this website, you probably already know what NLP is (to some degree) but for completeness sake, I will provide a brief description. 
                    Natural language processing (NLP) is a sub-field of machine learning focused on modelling the interactions between people and computers. 
                    The overarching goal is for the models to be able to read, comprehend, and generate human language in a way that is coherent and useable. How this arises differs
                    depending on the context and you will find that some models are better for specifics problems than others.</p>
                    
                    <p className="subsubsection-paragraph"> Some examples of these contexts include:
                    <ul>
                        <li><b>Machine Translation:</b> Translating text or speech from one language to another (e.g., Google Translate).</li>
                        <li><b>Sentiment Analysis:</b> Determining the sentiment or tone behind a piece of text (e.g., tweets, Instagram comments).</li>
                        <li><b>Chatbots & Virtual Assistants:</b> Simulating human-like conversations to provide information or support (e.g., Siri, Alexa).</li>
                        <li><b>Text Summarization:</b> Producing a concise summary of a longer text.</li>
                        <li><b>Information Retrieval:</b> Extracting relevant information from vast amounts of data (e.g., search engines).</li>
                    </ul>
                    The number of situations that can be handled by NLP has grown exponentially over the last couple of decades and we are at a
                    point now in development that we have general multi-purpose models that can efficienctly handle a wide array of questions/problems -- these are in the form of
                    large language models (e.g., OpenAIs GPT). The relevance of such models, whether it be for specific tasks or more general all-encompassing use-cases,
                    will only grow as artificial intelligence plays a more prominent role in our lives. It's an exciting time! </p>

                    <p className="subsubsection-paragraph">As with a lot of machine learning, the word "model" usually is shrouded in some level of ambiguity i.e., it's a catch-all for everything about the 
                    ~how that goes into actually making the object useful. The purpose of this website is to lift that veil and provide the reader with in-depth understanding about what
                    exactly is happening when we "train" these models. This will be a technically rigorous and thorough approach to what NLP is and how the models under its umbrella
                    work. I hope you enjoy it! </p>


                </section>
                
                
                <div className="subsubsection-navigation">
                    <Link to="/begin">← Begin</Link>
                    <Link to="/begin/history">History →</Link>
                </div>
            </main>
            
            <Footer />
        </div>
    );
}

export default WhatIsIt;
