import React from 'react';
import '../../styles/subsection.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import 'katex/dist/katex.min.css';
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function ELECTRA() {
    return (
        <div className="subsubsection-container">
            <Header />
            <div class="side-nav-container">
                <aside className="subsubsection-side-nav">
                    <a href="#1">Concept</a>
                    <a href="#2">Code</a>
                </aside>
            </div>
            
            <main className="subsubsection-content">
            <div className="titles"><h1>ELECTRA</h1></div>

            <section id="1" className="code-cleaned">
                    <h2>Concept</h2>
                        <p className="subsubsection-paragraph">
                        ELECTRA stands as a distinctive model in NLP, offering a pivot on how machines understand and learn from text. Instead of following the
                         traditional path of predicting the next word in a sequence or generating new text, ELECTRA trains by playing a clever game of "detective." It looks at sentences where some words 
                         have been deliberately replaced with impostors and learns to spot these fakes. This method is a bit like proofreading a document, where the focus is on identifying errors rather 
                         than writing from scratch. </p>

                            <p className="subsubsection-paragraph">
                            What makes ELECTRA particularly efficient is its focus on this error detection, or as it's technically known, distinguishing between "real" and "fake" tokens in text. 
                            By honing in on discrepancies within a sentence, ELECTRA develops a nuanced understanding of language, learning not just what words mean but how they fit together in 
                            context. This focused approach allows ELECTRA to achieve high levels of language understanding more quickly and with less computational power than many of its predecessors.
                            </p>

                            <p className="subsubsection-paragraph">
                            You can read more about this approach in: "ELECTRA: Pre-training Text Encoders as Discriminators Rather Than Generators," authored by Kevin Clark, Minh-Thang Luong, 
                            Quoc V. Le, and Christopher D. Manning
                            </p>

                            </section>

                            <section id="2" className="code-cleaned">
                            <h2>In Code</h2>
                    <p className="subsubsection-paragraph">
                    
<SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
{`from transformers import ElectraTokenizer, ElectraForSequenceClassification, pipeline

# Load the pre-trained ELECTRA model and tokenizer
model_name = "google/electra-small-discriminator"
tokenizer = ElectraTokenizer.from_pretrained(model_name)
model = ElectraForSequenceClassification.from_pretrained(model_name)

# Initialize a pipeline for sequence classification
nlp = pipeline("text-classification", model=model, tokenizer=tokenizer)

# Define the input text
text = "The movie was fantastic and full of suspense. Definitely a must-watch!"

# Use the pipeline to classify the text
classification = nlp(text)

# Print the classification result
print("Classification Result:", classification)
`}
</SyntaxHighlighter>
</p>
                    
                </section>

                <div className="subsubsection-navigation">
                    <Link to="/frontier/reformers">← Reformers</Link>
                    <Link to="/frontier/ctrl">CTRL →</Link>
                </div>
            </main>
            
            <Footer />
        </div>
    );
}

export default ELECTRA;
