import React from 'react';
import '../../styles/subsection.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';

function History() {
    return (
        <div className="subsubsection-container">
            <Header />
            <div class="side-nav-container">
                <aside className="subsubsection-side-nav">
                    <a href="#early-days">1950s to 1970s</a>
                    <a href="#statistical-revo">1980s to 1990s</a>
                    <a href="#acceleration">2000s to 2010s</a>
                    <a href="#current">Current</a>
                </aside>
            </div>
            
            <main className="subsubsection-content">
                <div className="titles"><h1>NLP History</h1></div>
                <section id="early-days">
                    <h2>1950s to 1970s</h2>
                    <p className="subsubsection-paragraph">The inception of NLP can be traced back to the 1950s, a period marked by pioneering experiments such as the 
                    Georgetown experiment in 1954. This experiment achieved the automatic translation of over sixty Russian sentences into English, laying the foundation for the potential
                    of machines to understand and generate human language. The subsequent decades of the 1950s to 1970s were characterized by an emphasis on rule-based methods. Linguists and 
                    researchers worked on encoding the intricacies of syntax and grammar into machines, aiming to bridge the gap between human language and computational understanding.</p>

                    <p className="subsubsection-paragraph"> The era witnessed the emergence of rudimentary chatbots and language processing systems, such as ELIZA and SHRDLU. These systems, although basic, 
                    showcased the nascent capabilities of human-computer interactions, hinting at the future of a more sophisticated and intuitive machine communication. Essentially, 
                    there wasn't much "learning" going on and instead, specific instructions were given to machines about where language artifacts should and shouldn't be. And, using these 
                    instructions (or rules), machines could make guesses on the structual accuracy of new sentences (or whatever the task may be). </p>
                </section>
                
                <section id="statistical-revo">
                    <h2>1980s to 1990s</h2>
                    <p className="subsubsection-paragraph">During the 1980s, the field of NLP began to witness a transformative phase focused on "Statistical NLP". 
                    This era marked a notable paradigm shift from the previous rule-based methodologies to more data-driven statistical approaches. I
                    nstead of relying solely 
                    on hard-coded grammar rules, the emphasis now lay in the immense power of data and probabilities. This shift was rooted in the understanding that human language, in 
                    all its variability and nuance, might be better captured by patterns within vast amounts of data rather than strict linguistic rules alone; you can consider the nuances of 
                    spoken vs. written language to see where hard-rules might fall short. </p>

                    <p className="subsubsection-paragraph">Parallel to this was the rise of corpus linguistics, which saw the assembly and utilization of vast amounts of text corpora for detailed linguistic 
                    analysis. Notable among these was the Brown Corpus, which became instrumental in various linguistic studies. Such corpora provided a wealth of real-world language data, 
                    facilitating the development and refinement of statistical models in NLP. This era also observed a cross-pollination of techniques from a number of fields. For instance, 
                    Hidden Markov Models, originally prominent in speech recognition, became foundational in 
                    NLP endeavors; you will learn more about these models later on.</p>

                    <p className="subsubsection-paragraph">Additionally, viable solutions for the problem of machine translation became much more of a reality during this period. 
                    The emergence of early statistical 
                    machine translation systems signaled a departure from the traditional rule-based translation techniques. These systems, powered by newfound statistical methodologies, 
                    began to offer translations that were more contextually nuanced and adaptable. The 80s and 90s were pivotal decades that shaped the trajectory of NLP, focusing 
                    it towards more flexible, data-centric, and probabilistic methodologies, setting the stage for the advancements that were to follow.</p>

                </section>
                
                <section id="acceleration">
                    <h2>2000s to 2010s</h2>

                    <p className="subsubsection-paragraph">In the 2000s, the field of NLP had another profound shift: the rise of neural networks. Central to this new 
                    age was the concept of word embeddings—dense vector representations of words that encapsulated their semantic meanings. Tools like Word2Vec and 
                    GloVe became emblematic of 
                    this era, transforming NLP by enabling machines to understand words in context, capturing relationships between them that were more akin to what we would 
                    observe in our interactions with people.</p>

                    <p className="subsubsection-paragraph">The neural network momentum didn't stop at word embeddings. The broader integration of deep learning into NLP heralded  
                    advancements across various tasks. Whether it was translation or sentiment analysis, deep neural networks began outperforming 
                    their predecessors, welcoming heightened accuracy and efficiency. Models like Recurrent Neural Networks (RNNs)  and their 
                    more sophisticated sibling, 
                    Long Short-Term Memory (LSTM) networks, took center stage. These models were specifically designed to handle sequence information in 
                    texts, making them invaluable for tasks 
                    where the order of words was crucial, such as in language modeling or text generation.</p>
                    
                    <p className="subsubsection-paragraph">However, the biggest advancement of this era was probably the introduction of attention mechanisms and, subsequently, the Transformer architecture. 
                    With the seminal paper "Attention is All You Need", we were introduced to a model that could attend to specific parts of a text, providing weighted importance to 
                    different sections. This shift was nothing short of revolutionary, laying the groundwork for models that were more efficient and adept at handling sequences. It eventually gave way  
                    for services such as ChatGPT to be realized.</p>

                </section>
                
                <section id="current">
                    <h2>Late 2010s to Present</h2>

                    <p className="subsubsection-paragraph">In the late 2010s, we saw even more transformative models. 
                    Central to this transformation was (BERT Bidirectional Encoder Representations from Transformers). This model demonstrated that 
                    pre-training language representations and 
                    fine-tuning them on specific tasks could lead to state-of-the-art performance across a number of NLP use-cases. Its bidirectional nature allowed it to understand context 
                    in a way that previous models couldn't, making it another important tool in the statistical modelling arsenal.</p>

                    <p className="subsubsection-paragraph">Following closely on BERT's heels were models 
                    like GPT-2 and GPT-3 by OpenAI. These behemoths, characterized by their vast number of 
                    parameters, showcased the potential of large transformers. Their capability to generate human-like text, often indistinguishable from actual human writing was the first big explicit 
                    foray into providing an ML interface to end-users. This era also solidified the principle of transfer learning in NLP. Instead of training models from scratch for 
                    each specific task, the community embraced the idea of training general-purpose models on massive datasets and then fine-tuning them for niche requirements, streamlining the 
                    process and achieving unprecedented efficiencies.</p>

                    <p className="subsubsection-paragraph"> Whether within research or for personal use, NLP has a lot 
                    of ground to cover still. The next decade will see the largest wide-spread adaption and commercialization of these kinds of tools. </p>

                </section>
                
                <div className="subsubsection-navigation">
                    <Link to="/begin/whatisit">← What is NLP?</Link>
                    <Link to="/begin/structure">Structure →</Link>
                </div>
            </main>
            
            <Footer />
        </div>
    );
}

export default History;
