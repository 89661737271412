import React from 'react';
import '../../styles/subsection.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import 'katex/dist/katex.min.css';
import { BlockMath, InlineMath } from 'react-katex';
// import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function ZeroShot() {
    return (
        <div className="subsubsection-container">
            <Header />
            <div class="side-nav-container">
                <aside className="subsubsection-side-nav">
                    <a href="#shot">Learning Techniques</a>
                </aside>
            </div>
            
            <main className="subsubsection-content">
                <div className="titles"><h1>Zero-shot, Few-shot, Many-shot Learning</h1></div>

                <section id="shot">
                <h2>Types of Learning</h2>
                <p className="subsubsection-paragraph">
         
                    There are a few types of ways that models can make inference about tasks. Let's first consider Zero-shot learning which refers to the ability of a model to correctly make inferences for tasks or recognize classes it has never explicitly seen during training. It relies heavily on
                     the transfer of knowledge from seen to unseen classes. Zero-shot learning is particularly useful in scenarios where it's impractical to have 
                      labeled data for every class, such as in natural language understanding or image classification with a vast number of classes. Models like GPT-3 demonstrate remarkable zero-shot
                       learning capabilities by generating relevant and coherent text based on a prompt alone, without task-specific training data.


                </p>

                    <p className="subsubsection-paragraph">
                    One-shot learning challenges the model to learn from a single example per class. It's a form of inductive transfer that leverages prior knowledge to make accurate predictions
                     from minimal data. Siamese networks and matching networks are examples of architectures designed for one-shot learning, often used in applications like face recognition, where collecting numerous samples
                     for each individual is unfeasible.
                    </p>

                    <p className="subsubsection-paragraph">
                    Few-shot learning involves training models on a very limited amount of data. Unlike one-shot learning, few-shot learning might have a handful of examples for each class. This
                     approach often involves meta-learning, where the model learns to learn from small data during training. The objective function can be formulated as minimizing the 
                     loss <InlineMath math="L" /> over a small support set <InlineMath math="S" />, which represents the few examples available per class.
                    </p>
                    <p className="subsubsection-paragraph">
                    <BlockMath math="L(S) = \sum_{(x, y) \in S} \ell(f(x; \theta), y)" />
                    Here, <InlineMath math="\ell" /> is a loss function, <InlineMath math="f" /> represents the model parameterized by <InlineMath math="\theta" />, and <InlineMath math="(x, y)" /> are 
                    the input-output pairs in the support set <InlineMath math="S" />.
                    </p>
                    <p className="subsubsection-paragraph">
                    Few-shot learning is widely used in NLP and computer vision tasks where labeled data is scarce. Models like T5 and BERT have been adapted for few-shot learning by leveraging their 
                    pre-trained knowledge in combination with a small number of task-specific examples.
                    </p>

                    <p className="subsubsection-paragraph">
                    The choice between zero-shot, one-shot, and few-shot learning depends on the availability of labeled data and the task at hand. Zero-shot learning is ideal when it's impossible to 
                    have examples for every class or task, relying on the model's ability to generalize from related tasks. One-shot learning is suitable for tasks like identification, where 
                    each class (e.g., an individual's face) can be defined by a single example. Few-shot learning is preferable when a small amount of data is available for each class, allowing for 
                    slight but significant fine-tuning of pre-trained models.
                    </p>

            </section>

            
                
                <div className="subsubsection-navigation">
                    <Link to="/existingmodels/advancedexisting">← Multimodal</Link>
                    <Link to="/existingmodels/huggingface">Huggingface →</Link>
                </div>
            </main>
            
            <Footer />
        </div>
    );
}

export default ZeroShot;
