import React from 'react';
import '../../styles/subsection.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import 'katex/dist/katex.min.css';
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function CTRL() {
    return (
        <div className="subsubsection-container">
            <Header />
            <div class="side-nav-container">
                <aside className="subsubsection-side-nav">
                    <a href="#1">Concept</a>
                    <a href="#2">Code</a>
                </aside>
            </div>
            
            <main className="subsubsection-content">
                <div className="titles"><h1>CTRL: Conditional Transformers</h1></div>

                <section id="1" className="code-cleaned">
                <h2>Concept</h2>
                    <p className="subsubsection-paragraph">
                    CTRL, short for "Conditional Transformer Language Model for Controllable Generation," is a unique language model designed to give users more control over the text generation process. Developed by
                     Salesforce Research, CTRL stands out because it can produce text based on specific prompts or conditions, such as style, content, and even the tone of the generated text. This level of
                      control opens up new possibilities for generating highly tailored and context-specific content, ranging from news articles in a particular style to creative stories set in specified genres.</p>

                        <p className="subsubsection-paragraph">
                        The key feature of CTRL is its ability to understand and respond to "control codes" that precede the text input. These codes can indicate a wide range of desired outputs, such as a particular writing style (e.g., "legal" or "romantic"), a specific theme (e.g., "space travel"), or a desired action (e.g., "translate to French"). 
                        By training on a diverse dataset that includes these control codes, CTRL learns to associate certain patterns and styles with specific codes, allowing it to adjust its text generation accordingly.
                        </p>

                        <p className="subsubsection-paragraph">
                        You can read more about this approach in: "CTRL: A Conditional Transformer Language Model for Controllable Generation," authored by Nitish Shirish Keskar, Bryan McCann, Lav R. Varshney, Caiming Xiong, 
                        and Richard Socher 
                        </p>

                        </section>

                        <section id="2" className="code-cleaned">
                        <h2>In Code</h2>
                <p className="subsubsection-paragraph">

                    This may run slow.
        
<SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
{`from transformers import CTRLTokenizer, CTRLLMHeadModel

# Load the pre-trained CTRL model and tokenizer
model_name = 'ctrl'
tokenizer = CTRLTokenizer.from_pretrained(model_name)
model = CTRLLMHeadModel.from_pretrained(model_name)

# Define a control code and input text. For example, 'Links' control code generates text in the style of a Wikipedia article.
control_code = "Links"
input_text = control_code + " The Great Pyramid of Giza"
input_tokens = tokenizer.encode(input_text, return_tensors="pt")

# Generate text using the model
output_tokens = model.generate(input_tokens, max_length=100, temperature=0.7)

# Decode the generated tokens to a readable string
generated_text = tokenizer.decode(output_tokens[0], skip_special_tokens=True)

print("Generated Text:", generated_text)
`}
</SyntaxHighlighter>
</p>
            
        
    </section>

    <div className="subsubsection-navigation">
        <Link to="/frontier/electra">← ELECTRA</Link>
        <Link to="/frontier/pegasus">Pegasus →</Link>
    </div>
</main>
            
            <Footer />
        </div>
    );
}

export default CTRL;
