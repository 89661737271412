import React from 'react';
import '../../styles/section.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

function NLPBasics() {
    return (
        <div className="section-container">
            <Header />
            <main className="section-content">
                <p className="section-intro-paragraph">Foundational knowledge within NLP</p>
                <div className="subsection-links">
                    <a href="/nlpbasics/data">Data & Representation</a>
                    <a href="/nlpbasics/ling">Linguistic Processing Techniques</a>
                    <a href="/nlpbasics/semantic">Semantic & Sentiment Analysis</a>
                    <a href="/nlpbasics/eval">Evaluation</a>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default NLPBasics;