
import React from 'react';
import '../../styles/subsection.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';

function About() {
    return (
        <div className="subsubsection-container">
            <Header />
            
            <main className="subsubsection-content">
                <section id="purpose"> 
                    <h2>About me</h2>
                    <p className="subsubsection-paragraph">Hello, I am Barinder Thind. I made this website for fun. You can find out more about what I do <a href="https://www.barinderthind.com">here.</a> </p>
                </section>
                
                <div className="subsubsection-navigation">
                    <Link to="/">Home</Link>
                </div>
            </main>
            
            <Footer />
        </div>
    );
}

export default About;
