import React from 'react';
import '../../styles/subsection.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';

function Ethics() {
    return (
        <div className="subsubsection-container">
            <Header />
            <div class="side-nav-container">
                <aside className="subsubsection-side-nav">
                    <a href="#understand">Understanding Biases</a>
                </aside> 
            </div>
            
            <main className="subsubsection-content">
                <div className="titles"><h1>LLM Ethics</h1></div>

                <section id="understand">
                    <h2>Understanding Model Biases</h2>
                    <p className="subsubsection-paragraph">
                        This section might be too complicated to say anything about.
                    </p>
                </section>
                
                
                <div className="subsubsection-navigation">
                    <Link to="/llms/applications">← LLM Applications</Link>
                    <Link to="/existingmodels">Current Models →</Link>
                </div>
            </main>
            
            <Footer />
        </div>
    );
}

export default Ethics;
