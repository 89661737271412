import React from 'react';
import '../../styles/subsection.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import 'katex/dist/katex.min.css';
// import { BlockMath, InlineMath } from 'react-katex';
// import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function HuggingFace() {
    return (
        <div className="subsubsection-container">
            <Header />
            <div class="side-nav-container">
                <aside className="subsubsection-side-nav">
                    <a href="#hugging">Ecosystem</a>
                    <a href="#trans">Transformers Library</a>
                    <a href="#cont">Contribution</a>
                </aside>
            </div>
            
            <main className="subsubsection-content">
                <div className="titles"><h1>Hugging Face</h1></div>

                <section id="hugging" className="code-cleaned">
                    <h2>Huggingface Ecosystem</h2>
                    <p className="subsubsection-paragraph">
                    Hugging Face is a pivotal entity in NLP and Machine Learning, renowned for its extensive ecosystem that includes a library of
                     pre-trained models, a model repository, and an active community. The company, founded in 2016 by Clément Delangue, Julien Chaumond, and Thomas Wolf, initially focused on creating
                      a social chatbot application. However, its direction shifted towards democratizing state-of-the-art NLP technologies, leading to the development of the transformers library.
                    </p>

                    <p className="subsubsection-paragraph">
                    There's also Hugging Face's Model Hub which is a central repository where the community can share and discover pre-trained models. This platform encourages collaboration and sharing, allowing users to 
                    upload their own models or download and fine-tune models shared by others. The Model Hub supports a variety of models beyond NLP, extending into computer vision and audio processing
                     domains.</p>
                </section>

                <section id="trans" className="code-cleaned">
                    <h2>Transformers & Tokenizers</h2>
                    <p className="subsubsection-paragraph">
                    The transformers library, initially released in 2018, is at the heart of Hugging Face's ecosystem. It provides a comprehensive collection of pre-trained models based on the Transformer 
                    architecture, such as BERT, GPT, T5, and many others, covering a wide range of tasks like text classification, translation, summarization, and question answering. The library is
                     designed with a user-friendly API, making these complex models accessible to both researchers and practitioners. It supports multiple deep learning frameworks, including TensorFlow
                      and PyTorch, facilitating seamless integration into existing projects.
                    </p>

                    <p className="subsubsection-paragraph">
                    The ecosystem is further enriched by the tokenizers and datasets libraries. The tokenizers library offers fast and efficient tokenization for various models, ensuring that text data 
                    is correctly preprocessed for model input. The datasets library provides easy access to a wide range of datasets and efficient data loading, significantly simplifying the data 
                    preparation process for ML tasks.
                    </p>
                </section>

                <section id="cont" className="code-cleaned">
                    <h2>Contribution</h2>

                    <p className='subsubsection-paragraph'>
                    The transformers library and the broader Hugging Face ecosystem have significantly lowered the barrier to entry for working with large ML models.
                     Whether for academic research, industry applications, or hobby projects, users can leverage these tools to achieve impressive results with minimal setup. The ecosystem's design
                      emphasizes ease of use without sacrificing flexibility and performance, making advanced ML techniques accessible to a broad audience.
                    </p>

                    <p className='subsubsection-paragraph'>
                    As ML continue to evolve, Hugging Face remains at the forefront, consistently introducing new features, models, and tools to its ecosystem. The company's commitment to
                     open-source principles, community engagement, and democratizing AI technology has established it as an important entity in the field. With ongoing advancements in areas like few-shot 
                     learning, multimodal models (e.g., CLIP), and efficient model scaling, Hugging Face should continue to remain relevant for the forseeable future. We've gone through 
                     plenty of examples already on how to download and use models from the repository so you can refer to those in case you wanted to use Hugging Face. 
                    </p>
                </section>


                
                
                <div className="subsubsection-navigation">
                    <Link to="/existingmodels/zeroshot">← Zero-Shot</Link>
                    <Link to="/frontier">Frontier →</Link>
                </div>
            </main>
            
            <Footer />
        </div>
    );
}

export default HuggingFace;
