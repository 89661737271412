import React from 'react';
import '../../styles/footer.css';
import { Link } from 'react-router-dom';

function Footer() {
    return (
        <footer className="footer">
            <Link to="/about">
                <i className="fa fa-globe fa-2x" aria-hidden="true"></i>
            </Link>
            <br />
            &copy; 2024 NLP Begin
        </footer>
    );
}

export default Footer;
