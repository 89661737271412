import React from 'react';
import '../../styles/subsection.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import 'katex/dist/katex.min.css';
import {  InlineMath } from 'react-katex';
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function Bert() {
    return (
        <div className="subsubsection-container">
            <Header />
            <div class="side-nav-container">
                <aside className="subsubsection-side-nav">
                    <a href="#bert">BERT</a>
                    <a href="#roberta">RoBERTa</a>
                    <a href="#albert">ALBERT: A Lite BERT</a>
                </aside>
            </div>
            
            <main className="subsubsection-content">
                <div className="titles"><h1>BERT and Its Variants</h1></div>

                <section id="bert" className="code-cleaned">
                    <h2>BERT</h2>
                    <p className="subsubsection-paragraph">
                    BERT (Bidirectional Encoder Representations from Transformers) was introduced by Google in 2018. 
                    Unlike standard Transformers that process sequences in one direction (either left-to-right or right-to-left), BERT is designed to understand the context of a word based on all 
                    of its surroundings (both left and right of the word). This bidirectional context understanding is achieved through a mechanism called "Masked Language Model" (MLM) and "Next 
                    Sentence Prediction" (NSP), which are fundamental to its training process. You can refer back to the bi-directional RNN section to get at least an analgous architecture or reasoning 
                    behind why this would be helpful
                    </p>

                    <p className="subsubsection-paragraph">
                    The core innovation in BERT, compared to a standard Transformer, lies in its training approach. In the MLM task, BERT randomly masks some percentage of the input tokens, and 
                    the objective is to predict the masked word based only on its context. This forces BERT to develop a deep contextual
                     understanding of the language.
                    </p>

                    <p className='subsubsection-paragraph'>
                    Another aspect is the NSP task, where BERT learns to predict whether two sentences are consecutive. This further enriches its understanding of language structure and coherence.
                    BERT's architecture is similar to the encoder component of the original Transformer model, consisting of multiple layers of multi-headed self-attention and fully connected feed-forward 
                    networks.
                     However, BERT's use of bidirectional context and its pre-training tasks (MLM and NSP) set it apart. BERT's success has spawned numerous variants and inspired further innovations in 
                     the field, 
                     making it a cornerstone in the development of NLP models and applications. Its pre-trained model serves as a powerful foundation for fine-tuning on specific NLP tasks, enabling researchers and
                      practitioners to achieve state-of-the-art results with comparatively minimal effort.
                      <SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
{`from transformers import BertTokenizer, BertForTokenClassification
from transformers import pipeline

# Load the pre-trained BERT model and tokenizer for NER
tokenizer = BertTokenizer.from_pretrained('dbmdz/bert-large-cased-finetuned-conll03-english')
model = BertForTokenClassification.from_pretrained('dbmdz/bert-large-cased-finetuned-conll03-english')

# Create a pipeline for NER
ner_pipeline = pipeline("ner", model=model, tokenizer=tokenizer)

# Sample text
text = "Hugging Face Inc. is a company based in New York City. Its technology is used by more than 5,000 organizations worldwide."

# Use the NER pipeline to find entities in the text
entities = ner_pipeline(text)

# Print detected entities and their labels
for entity in entities:
    print(f"Entity: {entity['word']}, Label: {entity['entity']}")
`}
    </SyntaxHighlighter>
                    </p>


                    {/* Python Example */}
    
                </section>

                <section id="roberta" className="code-cleaned">
                    <h2>RoBERTa: Robustly Optimized BERT</h2>
                    <p className="subsubsection-paragraph">
                    RoBERTa (Robustly Optimized BERT Pretraining Approach) builds upon the foundation laid by BERT, introducing key optimizations that significantly improve its performance across a 
                    range of NLP tasks. Developed by Facebook AI, RoBERTa revisits the BERT pre-training procedure and makes several crucial adjustments that enhance model robustness and efficiency.
                    One of the primary enhancements in RoBERTa is the elimination of the Next Sentence Prediction task. RoBERTa's creators found that removing NSP 
                    and focusing solely on the Masked Language Model task, with more extensive and dynamic masking patterns, leads to better language understanding. The MLM objective in RoBERTa 
                    remains similar to BERT, aiming to predict masked tokens within a sequence based on their context.  However, RoBERTa employs dynamic masking — a different set of words is masked each
                     time the same sequence is fed into the model during training, enhancing the model's ability to learn diverse contexts.
                    </p>

                    <p className="subsubsection-paragraph">
                    Another key improvement is in training data size and batch processing. RoBERTa is trained on a much larger corpus and with significantly larger mini-batches than BERT, which 
                    contributes to its enhanced performance. RoBERTa also adjusts the hyperparameters related to the training process, such as learning rate, the number of training steps, and 
                    the attention mechanism, optimizing them for better performance.
                    </p>

                    <p className="subsubsection-paragraph">
                    In practical applications, RoBERTa has shown superior results compared to BERT on various benchmarks, including GLUE, RACE, and SQuAD, making it a preferred choice for tasks like sentiment 
                    analysis, 
                    question answering, and document 
                    classification.
                     <SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
{`from transformers import RobertaTokenizer, RobertaForSequenceClassification
from transformers import pipeline

# Load the pre-trained RoBERTa model and tokenizer for sentiment analysis
tokenizer = RobertaTokenizer.from_pretrained('cardiffnlp/twitter-roberta-base-sentiment')
model = RobertaForSequenceClassification.from_pretrained('cardiffnlp/twitter-roberta-base-sentiment')

# Create a pipeline for sentiment analysis
sentiment_pipeline = pipeline("sentiment-analysis", model=model, tokenizer=tokenizer)

# Sample text
text = "RoBERTa models are amazing for natural language processing tasks!"

# Use the sentiment analysis pipeline to assess the sentiment of the text
sentiment = sentiment_pipeline(text)

# Print the sentiment analysis result
print(f"Sentiment: {sentiment[0]['label']}, Score: {sentiment[0]['score']:.4f}")
`}
    </SyntaxHighlighter>
                    </p>

                    

                </section>

                <section id="albert" className="code-cleaned">
                    <h2>ALBERT: A Lite BERT</h2>
                    <p className="subsubsection-paragraph">
                    ALBERT (A Lite BERT) is a variation of BERT that introduces solutions to reduce model size and increase training speed, making it more efficient without compromising performance -- 
                    you can think of this as a distillation. 
                    Developed by Google Research, ALBERT addresses some of the key limitations of BERT, particularly the massive computational resources required for training and deploying large models.
                    One of the cornerstone innovations in ALBERT is parameter-reduction techniques that decrease the model's memory consumption and increase its training speed. ALBERT employs two main
                     strategies for this:

                     <ol className="subsubsection-list">
                        <li>
                            <strong>Factorized Embedding Parameterization:</strong> Instead of utilizing the same dimensions for hidden layers and word embeddings, ALBERT employs a strategy to decompose
                             the embedding matrix into two smaller matrices. This approach significantly reduces the number of parameters, given that the embedding layer is typically one of the most 
                             parameter-dense parts of models like BERT. Mathematically, this can be expressed by dividing an embedding matrix <InlineMath math="E" /> of 
                             dimensions <InlineMath math="V \times H" /> (where <InlineMath math="V" /> denotes the vocabulary size and <InlineMath math="H" /> represents the size of the hidden layers) into
                              two matrices <InlineMath math="E_1" /> and <InlineMath math="E_2" />. Here, <InlineMath math="E_1" /> has 
                              dimensions <InlineMath math="V \times E" /> and <InlineMath math="E_2" /> is <InlineMath math="E \times H" /> with <InlineMath math="E" /> being significantly smaller 
                              than both <InlineMath math="V" /> and <InlineMath math="H" />.
                        </li>
                        <li>
                            <strong>Cross-layer Parameter Sharing:</strong> This approach shares parameters across all layers within the model, extending beyond merely the attention heads or 
                            feed-forward networks. Such parameter sharing minimizes the total count of unique parameters, fostering more effective and efficient learning. When considering a model 
                            comprised of <InlineMath math="L" /> layers, rather than maintaining <InlineMath math="L" /> distinct parameter sets, ALBERT adopts a singular set of parameters 
                            distributed across all layers.
                        </li>
                        </ol>

                     Besides these parameter-reduction strategies, ALBERT also modifies the pre-training tasks. It replaces the Next Sentence Prediction task of BERT with a Sentence-Order Prediction (SOP)
                      task. SOP is designed to better capture the coherence and flow of sentences, requiring the model to predict the correct order of two consecutive segments of text. In practice, 
                      ALBERT has demonstrated remarkable performance on benchmark NLP tasks, achieving competitive or even superior results compared to its predecessors while being significantly more
                       efficient. Its applications span a wide range of NLP tasks, including but not limited to question answering, named entity recognition, and sentiment analysis. ALBERT's efficiency 
                       makes it particularly appealing for scenarios where computational resources are limited or when deploying large-scale NLP models in production environments.
                       <SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
{`from transformers import AlbertTokenizer, AlbertForSequenceClassification
from transformers import pipeline

# Load the pre-trained ALBERT model and tokenizer for sentiment analysis
tokenizer = AlbertTokenizer.from_pretrained('textattack/albert-base-v2-yelp-polarity')
model = AlbertForSequenceClassification.from_pretrained('textattack/albert-base-v2-yelp-polarity')

# Create a pipeline for sentiment analysis
sentiment_pipeline = pipeline("sentiment-analysis", model=model, tokenizer=tokenizer)

# Sample text
text = "The new coffee shop on the corner is fantastic!"

# Use the sentiment analysis pipeline to assess the sentiment of the text
sentiment = sentiment_pipeline(text)

# Print the sentiment analysis result
print(f"Sentiment: {sentiment[0]['label']}, Score: {sentiment[0]['score']:.4f}")
`}
    </SyntaxHighlighter>
                    </p>

                    

                </section>
                
                
                <div className="subsubsection-navigation">
                    <Link to="/existingmodels">← Current Models</Link>
                    <Link to="/existingmodels/generativepretrained">Pre-Trained Generative →</Link>
                </div>
            </main>
            
            <Footer />
        </div>
    );
}

export default Bert;
