import React from 'react';
import '../../styles/section.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

function ML() {
    return (
        <div className="section-container">
            <Header />
            <main className="section-content">
                <p className="section-intro-paragraph">Learn about all kinds of NLP models</p>
                <div className="subsection-links">
                    <a href="/ml/mlalgo">Foundational ML Algorithms</a>
                    <a href="/ml/classic">Classic NLP</a>
                    <a href="/ml/cnn">CNNs & Variants</a>
                    <a href="/ml/rnn">RNNs & Variants</a>
                    <a href="/ml/seq2seq">Seq2Seq & Transformers</a>
                    <a href="/ml/adv">Other Architectures</a>
                    <a href="/ml/transfer">Transfer Learning</a>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default ML;