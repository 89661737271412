import React from 'react';
import '../../styles/subsection.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import 'katex/dist/katex.min.css';
import { BlockMath } from 'react-katex';
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';


function Semantics() {
    return (
        <div className="subsubsection-container">
            <Header />
            <div class="side-nav-container">
                <aside className="subsubsection-side-nav">
                    <a href="#semantic">Semantic Analysis</a>
                    <a href="#sentiment">Sentiment Analysis</a>
                    <a href="#pragmatics">Pragmatics</a>
                    <a href="#world">World Commonalities</a>
                </aside>
            </div>
            
            <main className="subsubsection-content">
                <div className="titles"><h1>Semantics & Sentiment</h1></div>

                <section id="semantic" className="code-cleaned">
                <h2>Semantic Analysis</h2>

                <p className="subsubsection-paragraph">
                    Semantic analysis in NLP is the process of understanding the meaning and interpretation of words, phrases, and sentences in their specific contexts. It goes beyond the 
                    mere identification of words to comprehending their underlying meanings, relationships, and implied concepts. It also deals with understanding metaphors, idioms, and
                     other figurative language nuances. This understanding is fundamental for various applications, 
                    from search engines to chatbots.
                </p>

                {/* <p className="subsubsection-paragraph">
                    At its core, semantic analysis involves the understanding of lexical semantics (meanings of words) and compositional semantics (how combinations of words create new meanings). 
                    Semantic analysis tackles the challenge of contextually interpreting language, considering factors like polysemy (words with multiple meanings) and homonymy (different words with 
                    the same form). 
                </p> */}

                <h4>Word Sense Disambiguation</h4>
                <p className="subsubsection-paragraph">
                    Word Sense Disambiguation (WSD) is a crucial task in semantic analysis, aiming to identify the correct meaning of a word in a given context. This is particularly challenging due 
                    to the inherent ambiguity in natural languages. Techniques for WSD include supervised learning, where models are trained on annotated examples, and unsupervised methods, which 
                    rely on knowledge bases like WordNet. An example is distinguishing "bank" as a financial institution versus a river bank based on surrounding words in a sentence. You could also 
                    consider methods like attention mechanisms that we will learn a lot about later on. 
                </p>

                <h4>Semantic Similarity</h4>
                <p className="subsubsection-paragraph">
                    Semantic similarity measures how closely related two pieces of text are in terms of meaning. This involves algorithms that can assess semantic content and relate it to human 
                    judgment of similarity. Techniques include using vector space models where words or phrases are represented as vectors, and their semantic similarity is calculated using metrics 
                    like cosine similarity:
                    <BlockMath math="\text{Cosine Similarity} = \frac{A \cdot B}{\|A\| \|B\|}" />
                    This concept is instrumental in applications like document clustering, information retrieval, and paraphrase detection.
                </p>

                <h4>In Code</h4>
                <p className="subsubsection-paragraph">
            A practical application of semantic analysis is in automated text summarization. The following Python example demonstrates a simple approach to summarizing text using NLTK and frequency-based filtering of sentences.
            
            <SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
{`from transformers import pipeline

# Initialize a summarization pipeline
summarizer = pipeline("summarization")

# Example text
text = "Natural Language Processing (NLP) is a field of computer science, artificial intelligence, and linguistics concerned with the interactions between computers and human (natural) languages. As such, NLP is related to the area of human-computer interaction. Many challenges in NLP involve natural language understanding."

# Generating summary
summary = summarizer(text, max_length=50, min_length=25, do_sample=False)

# Output the summary
print(summary[0]['summary_text'])`}
        </SyntaxHighlighter>
            </p>

        
            </section>


                
            <section id="sentiment" className="code-cleaned">
            <h2>Sentiment Analysis</h2>
            <p className="subsubsection-paragraph">
                Sentiment analysis is a sub-field of NLP that focuses on identifying and categorizing opinions expressed in text to determine the writer's attitude 
                towards a particular topic or the overall contextual polarity of the text.
            </p>

            <p className="subsubsection-paragraph">
                Sentiment analysis involves the computational study of opinions, sentiments, subjectivity, and emotions expressed in text. It typically classifies the sentiment of a given text at 
                the document, sentence, or feature/aspect level into either one of positive, negative, or neutral. Advanced 
                sentiment analysis can detect more complex states like sarcasm, urgency, or confidence.
            </p>

            <p className="subsubsection-paragraph">
                Common techniques in sentiment analysis include:
                <ul>
                    <li><strong>Lexicon-Based Approaches</strong>: Utilize a pre-compiled list of words with assigned sentiment scores. The overall sentiment of a text is determined based on the 
                    aggregate scores of its words.</li>
                    <li><strong>Machine Learning Approaches</strong>: Use various algorithms (like Naive Bayes, SVM, neural networks) to learn from a labeled dataset and predict sentiment. These 
                    methods can be more flexible and context-aware than lexicon-based approaches.</li>
                </ul>
                The choice of technique often depends on the specific requirements of the task and the availability of labeled data.
            </p>

            <p className="subsubsection-paragraph">
                Another approach is aspect-based sentiment analysis (ABSA) which is a more granular approach that goes beyond overall sentiment analysis to determine the sentiment towards specific aspects of a product or 
                service. For example, in a review like "The camera of this phone is great, but the battery life is poor," ABSA would separately identify and analyze the sentiments towards 
                "camera" (positive) and "battery life" (negative). ABSA is particularly useful in areas like market research and product analysis, where understanding specific facets of customer 
                feedback is essential.
            </p>

            <h4>In Code</h4>
            <p className="subsubsection-paragraph">
                A practical application of sentiment analysis is determining the sentiment polarity of customer reviews. Below is a Python example using the TextBlob library, which provides a 
                simple API for common NLP tasks, including sentiment analysis.

                <SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
{`from textblob import TextBlob

# Sample text
text = "I recently bought a new phone. Its camera is amazing but the battery life is too short."

# Create a TextBlob object
blob = TextBlob(text)

# Output sentiment polarity
print("Sentiment Polarity:", blob.sentiment.polarity)

# Output:
# Sentiment Polarity: 0.39166666666666666`}
    </SyntaxHighlighter>
            </p>

            <p className="subsubsection-paragraph">
                In this example, the sentiment polarity is a float within the range [-1.0, 1.0], where -1.0 signifies negative sentiment and 1.0 signifies positive sentiment. The TextBlob library 
                internally uses pattern analyzer for English text but can be configured with other machine learning models for more advanced analysis.
            </p>
        </section>


        <section id="pragmatics" className="code-cleaned">
        <h2>Pragmatics</h2>
        <p className="subsubsection-paragraph">
            Pragmatics relates to how context influences the interpretation of language. Unlike syntax or semantics, pragmatics deals with the aspects of meaning that are derived from the 
            speakers' intentions and the listeners' interpretations, considering the context and the communicative function of language.
        </p>

        <p className="subsubsection-paragraph">
            Pragmatics encompasses the study of language from the point of view of users, particularly of contextual meaning and how people understand each other in communication. This includes 
            understanding language beyond its literal meaning, considering factors like speaker intent, social norms, and cultural context.
        </p>
        
        <h4>Speech Acts & Communicative Functions</h4>
        <p className="subsubsection-paragraph">
            Speech acts theory, proposed by J.L. Austin and further developed by John Searle, categorizes utterances into types based on the speaker's intention: assertives (statements), 
            directives (requests, commands), commissives (promises, offers), expressives (expressions of feelings), and declaratives (changing the reality). Understanding these acts is crucial for 
            NLP systems in tasks like dialogue generation and intent classification.
        </p>

        <h4>Conversational Implicature</h4>
        <p className="subsubsection-paragraph">
            Conversational implicature deals with what is implied but not explicitly stated in a conversation. Grice's Maxims (Quality, Quantity, Relevance, and Manner) guide the interpretation of 
            implicature. NLP systems need to understand these subtleties to effectively process natural language, especially in conversational AI.
        </p>

        <h4>Discourse Analysis</h4>
        <p className="subsubsection-paragraph">
            Discourse analysis in NLP examines how sequences of sentences (discourses) convey meaning, focusing on properties like coherence and cohesiveness. This includes analyzing text 
            structure, narrative flow, and the relationships between sentences to understand the overall context.
        </p>

        <h4>In Code</h4>
        <p className="subsubsection-paragraph">
            An example of pragmatics in action can be seen in sentiment analysis, where understanding context is key. Below is a Python snippet demonstrating a simple way to extract context 
            from text:

            <SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
{`from textblob import TextBlob

# Example text with sarcasm
text = "Oh great! Another rainy day."

# Create a TextBlob object
blob = TextBlob(text)

# Output sentiment polarity
print("Sentiment Polarity:", blob.sentiment.polarity)

# Contextual understanding is required to interpret this correctly`}
    </SyntaxHighlighter>
        </p>

                    <p className="subsubsection-paragraph">
                        This example shows the limitations of basic sentiment analysis tools in understanding pragmatics, as they might miss the sarcasm implied by the context.
                    </p>
                </section>

                <section id="world" className="code-cleaned">
                <h2>World Commonalities</h2>
                <p className="subsubsection-paragraph">
                    World commonalities in NLP refer to the shared concepts, structures, and knowledge that are universally recognized and understood. These commonalities can be important for 
                    enabling systems to interact with real-world knowledge and perform tasks like information retrieval, question answering, and language understanding more effectively.
                </p>

                <h4>Ontologies</h4>
                <p className="subsubsection-paragraph">
                    Ontologies are structured frameworks that represent knowledge as a set of concepts within a domain and the relationships between those concepts. They enable NLP 
                    systems to understand and process the complex relationships in data. An ontology for a medical NLP system, for example, might include concepts like diseases, symptoms, and 
                    treatments, and define the relationships between them.
                </p>

                <h4>Knowledge Graphs</h4>
                <p className="subsubsection-paragraph">
                    Knowledge graphs represent a collection of interlinked descriptions of entities -- objects, events, or concepts. They provide a way for NLP systems to leverage structured 
                    information about the world, which can be used in tasks like semantic search, recommendation systems, and virtual assistants. Google's Knowledge Graph, for instance,
                     enhances its search engine's ability to understand the context of a search query and provide more accurate and relevant results.
                </p>

                <h4>Common Sense Reasoning</h4>
                <p className="subsubsection-paragraph">
                    Common sense reasoning involves enabling NLP systems to make presumptions about the world that are obvious to humans but not explicitly stated. This includes understanding
                     idioms, metaphors, and implied meanings. Common sense reasoning is challenging for NLP systems, as it requires a vast amount of world knowledge and the ability to apply it 
                     flexibly in different contexts. Projects like OpenAI's GPT-3 have made significant strides in this area, though it remains an active field of research.
                </p>
            </section>
                
                
                <div className="subsubsection-navigation">
                    <Link to="/nlpbasics/ling">← Linguistics</Link>
                    <Link to="/nlpbasics/eval">Evaluation in NLP →</Link>
                </div>
            </main>
            
            <Footer />
        </div>
    );
}

export default Semantics;
