import React from 'react';
import '../../styles/subsection.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import decisiontree from '../../media/FoundationalMLAlgo/decisiontree.png';
import svm from '../../media/FoundationalMLAlgo/svm.png';

function OGML() {
    return (
        <div className="subsubsection-container">
            <Header />
            <div class="side-nav-container">
                <aside className="subsubsection-side-nav">
                    <a href="#svm">Support Vector Machines</a>
                    <a href="#rf">Random Forests</a>
                    <a href="#naive">Naive Bayes</a>
                </aside>
            </div>
            
            <main className="subsubsection-content">
                <div className="titles"><h1>Foundational Models</h1></div>

                <section id="svm" className="code-cleaned">
                    <h2>Support Vector Machines</h2>
                    <p className="subsubsection-paragraph">
                        Support Vector Machines (SVMs) are a class of supervised learning algorithms, renowned for their effectiveness in high-dimensional spaces. They are used for classification, 
                        regression, and outlier detection, excelling particularly in complex but small- or medium-sized datasets.
                    </p>

                    <p className="subsubsection-paragraph">
                    <table style={{ width: '100%', borderCollapse: 'collapse', margin: '10px 0' }}>
                    <tbody>
                        <tr>
                        <td style={{ padding: '8px', border: '1px solid #ddd' }}>Use Cases</td>
                        <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                            <span style={{ color: '#333399' }}>Text categorization</span>, 
                            <span style={{ color: '#008000' }}> Spam detection</span>, 
                            <span style={{ color: '#ff4500' }}> Sentiment analysis</span>, 
                            <span style={{ color: '#1e90ff' }}> Information retrieval</span>
                        </td>
                        </tr>
                        <tr>
                        <td style={{ padding: '8px', border: '1px solid #ddd' }}>Python Libraries</td>
                        <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                            <span style={{ color: '#6a5acd' }}>Scikit-learn (sklearn.svm.SVC)</span>, 
                            <span style={{ color: '#20b2aa' }}> Libsvm</span>, 
                            <span style={{ color: '#ff6347' }}> Liblinear</span>
                        </td>
                        </tr>
                        <tr>
                        <td style={{ padding: '8px', border: '1px solid #ddd' }}>O-Complexity (Worst Case)</td>
                        <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                            <span>O(n<sup>3</sup>)</span>
                        </td>
                        </tr>
                        <tr>
                        <td style={{ padding: '8px', border: '1px solid #ddd' }}>Relevant Papers</td>
                        <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                            <span>"Support-Vector Networks"</span> by Cortes and Vapnik, 1995
                        </td>
                        </tr>
                    </tbody>
                    </table>
                </p>


                    <h4>SVM Foundations</h4>
                    <p className="subsubsection-paragraph">
                        The SVM algorithm seeks a hyperplane in an N-dimensional space (N - the number of features) that distinctly classifies data points into separate categories. The hyperplane 
                        is mathematically defined as <BlockMath math="w \cdot x + b = 0" /> where <InlineMath math="w" /> represents the weight vector, <InlineMath math="x" /> is a feature vector, 
                        and <InlineMath math="b" /> is the bias. The optimal hyperplane is the one that maximizes the margin, the distance between itself and the nearest data point of any class. Class 
                        in this case is the possible labels within your data set (for example, (spam, not spam)). There are two types of these margins -- hard margins and soft margins. The difference 
                        is that soft margins allow for error (misclassifications) which is a reality of mostly all real life data sets however, for explanation purposes, let's focus on the hard 
                        margin problem.

                        <figure className="flex-container-caption">
                        <img src={svm} alt="Descriptive alternative text" className="image-medium"/><br/>
                        <figcaption>There are two classes of data points: class A and class B, represented by two different symbols (circles and squares). These classes 
                            are separated by a decision boundary known as the optimal hyperplane. The hyperplane is shown as a solid line, and the margins are represented by dashed lines. 
                            Data points that are closest to the hyperplane, known as support vectors, are on the dashed lines. These support vectors are critical for defining the margin
                             and the position of the hyperplane; <a href="https://www.researchgate.net/figure/Classification-of-data-by-support-vector-machine-SVM_fig8_304611323" target="_blank" rel="noopener noreferrer">image source</a>.</figcaption>
                        </figure>


                    
                    
                    <p>
                        The objective of the hard margin SVM is to maximize the margin between the two classes, which is equivalent to minimizing the norm of the weight 
                        vector <InlineMath math="\|\mathbf{w}\|" />, subject to the constraint that all data points are classified correctly. Mathematically, this can be expressed as:
                    </p>
                    <BlockMath math="\min \frac{1}{2} \|\mathbf{w}\|^2" />
                    <p>
                        Subject to the constraints:
                    </p>
                    <BlockMath math="y_i (\mathbf{w} \cdot \mathbf{x}_i + b) \geq 1, \quad \forall i" />
                    <p>
                        where <InlineMath math="\mathbf{x}_i"  /> are the feature vectors, <InlineMath math="y_i"  /> are the labels (with values +1 or -1), <InlineMath math="\mathbf{w}"  /> is the
                         normal vector to the hyperplane, and <InlineMath math="b"  /> is the bias term.
                    </p>


                    <p>
                        Support vectors are the data points that lie closest to the decision surface (hyperplane). They are critical as they are the points that directly influence the position and orientation 
                        of the hyperplane. For these points, the following condition holds:
                    </p>
                    <BlockMath math="y_i (\mathbf{w} \cdot \mathbf{x}_i + b) = 1" />

{/* 
                    <p>
                        The optimal hyperplane is the one that separates the two classes while maximizing the margin. The margin is given by <InlineMath math="\frac{2}{\|\mathbf{w}\|}"  />, and thus
                         maximizing the margin is equivalent to minimizing <InlineMath math="\|\mathbf{w}\|"  /> or <InlineMath math="\frac{1}{2}\|\mathbf{w}\|^2"  /> for simplification.
                    </p> */}

                    <p>
                        The optimization problem described above is a quadratic programming problem. It can be solved using Lagrange multipliers, which transform the constrained optimization problem into
                         an unconstrained one. The Lagrangian is given by:
                    </p>
                    <BlockMath math="\mathcal{L}(\mathbf{w}, b, \mathbf{\alpha}) = \frac{1}{2} \|\mathbf{w}\|^2 - \sum_{i=1}^{n} \alpha_i [y_i (\mathbf{w} \cdot \mathbf{x}_i + b) - 1]" />
                    <p>
                        where <InlineMath math="\mathbf{\alpha}"  /> are the Lagrange multipliers. The conditions for optimality (Karush-Kuhn-Tucker conditions) lead to the dual problem, which only 
                        involves the Lagrange multipliers <InlineMath math="\mathbf{\alpha}"  />.
                    </p>

                        The solution to the dual problem gives the optimal values of <InlineMath math="\mathbf{\alpha}"  />, which are used to 
                        compute the optimal <InlineMath math="\mathbf{w}"  /> and <InlineMath math="b"  />, defining the optimal hyperplane. To be completely honest, this probably isn't a great explanation 
                        but SVMs are not used that much for most NLP tasks so knowing the underlying math here is largely pointless but if you can read math well, this may have made some sense. 
                        It basically boils down to finding the parameters that give you a line so that there is the best possible separation between your classes. There is also something to be 
                        said about the kernel trick but I will skip on all that; Let's instead focus on hyperparameters and an actual coding example for an NLP problem.

                        </p>

                    <h4>Hyperparameters</h4>
                    <p className="subsubsection-paragraph">
                    <ul>
                        <li>
                        <strong>Kernel:</strong> The kernel function transforms the input data into a higher-dimensional space. Choices include:
                        <ul>
                            <li>Linear: <InlineMath math="K(\mathbf{x_i}, \mathbf{x_j}) = \mathbf{x_i}^\top \mathbf{x_j}" /></li>
                            <li>Polynomial: <InlineMath math="K(\mathbf{x_i}, \mathbf{x_j}) = (\gamma \mathbf{x_i}^\top \mathbf{x_j} + r)^d" />, where <InlineMath math="\gamma" />, <InlineMath math="r" />, and <InlineMath math="d" /> are kernel parameters.</li>
                            <li>RBF (Radial Basis Function): <InlineMath math="K(\mathbf{x_i}, \mathbf{x_j}) = \exp(-\gamma \|\mathbf{x_i} - \mathbf{x_j}\|^2)" />, where <InlineMath math="\gamma" /> is a kernel parameter.</li>
                        </ul>
                        In NLP, linear kernels are often effective due to the high-dimensional nature of text data.
                        </li>
                        <li>
                        <strong>Regularization Parameter (C):</strong> Controls the trade-off between low training error and maintaining model simplicity. A high <InlineMath math="C" /> value can 
                        lead to overfitting, a common concern in NLP due to sparse data.
                        </li>
                        <li>
                        <strong>Gamma (<InlineMath math="\gamma" />):</strong> For non-linear kernels, this parameter influences the decision boundary's curvature. In NLP, an
                         appropriate <InlineMath math="\gamma" /> value can help in capturing linguistic patterns that are not linearly separable.
                        </li>
                    </ul>
                    </p>
                    
                    <h4>In Code</h4>
                    <p className="subsubsection-paragraph">
                        Here's a Python example demonstrating the use of SVM for text classification:
                        <SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
                {`from sklearn.datasets import fetch_20newsgroups
from sklearn import svm
from sklearn.feature_extraction.text import TfidfVectorizer
from sklearn.model_selection import train_test_split
from sklearn.metrics import accuracy_score

# Load the dataset
newsgroups_train = fetch_20newsgroups(subset='train', categories=['sci.space', 'rec.autos'])

# Sample text data and labels
texts = newsgroups_train.data
labels = newsgroups_train.target

# Convert texts to TF-IDF features
vectorizer = TfidfVectorizer()
X = vectorizer.fit_transform(texts)

# Split data into training and test sets
X_train, X_test, y_train, y_test = train_test_split(X, labels, test_size=0.3, random_state=42)

# Train an SVM classifier
clf = svm.SVC(kernel='linear')
clf.fit(X_train, y_train)

# Predict and evaluate
predictions = clf.predict(X_test)
accuracy = accuracy_score(y_test, predictions)

# Output the accuracy
print(f'Accuracy: {accuracy:.2f}')`}
            </SyntaxHighlighter>
                    </p>

                </section>
                
                <section id="rf"  className="code-cleaned">
                <h2>Random Forests</h2>
                <p className="subsubsection-paragraph">
                    Random Forests are a robust machine learning method used for classification and regression. They operate by constructing a multitude of decision trees at training time and 
                    outputting the class that is the mode of the classes (in classification) or mean prediction (in regression) of the individual 
                    trees -- I'll be discussing these decisions trees before heading into Random Forests. Just as with SVMs in the previous 
                    section, Random Forests can be used for NLP problems anytime you have a label attached to a piece of text -- the text can be
                    represented in its numerical form and used as covariates for the associated label.
                </p>

                <p className="subsubsection-paragraph">
                    <table style={{ width: '100%', borderCollapse: 'collapse', margin: '10px 0' }}>
                        <tbody>
                            <tr>
                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>Use Cases</td>
                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                                    <span style={{ color: '#333399' }}>Text classification</span>,
                                    <span style={{ color: '#008000' }}> Sentiment analysis</span>,
                                    <span style={{ color: '#ff4500' }}> Language identification</span>,
                                    <span style={{ color: '#1e90ff' }}> Document categorization</span>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>Python Libraries</td>
                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                                    <span style={{ color: '#6a5acd' }}>Scikit-learn (sklearn.ensemble.RandomForestClassifier)</span>,
                                    <span style={{ color: '#20b2aa' }}> RandomForestRegressor</span>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>O-Complexity (Worst Case)</td>
                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                                    <span>O(n log n)</span>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>Relevant Papers</td>
                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                                    <span>"Random Forests"</span> by Leo Breiman, 2001
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </p>





                <h4>Decision Trees</h4>
                <p className="subsubsection-paragraph">
                Decision Trees are a non-parametric supervised learning method used for classification and regression tasks. 
                The goal is to create a model that predicts the value of a target variable by learning simple decision rules
                 inferred from the data features. The "Information Gain" from a particular split is calculated using the concept of entropy, a measure of
                  the amount of uncertainty or impurity in the dataset. The entropy of a dataset is given by the equation:

                  <BlockMath math="H(S) = - \sum_{i=1}^{n} p_i \log_2 p_i" />

                  where <InlineMath math="H(S)" /> is the entropy of set <InlineMath math="S" />, <InlineMath math="p_i" /> is the proportion of the samples
                   that belong to class <InlineMath math="i" />, and <InlineMath math="n" /> is the number of classes.
                   The Information Gain is then calculated as the difference in entropy before and after the split. The attribute with the highest Information 
                   Gain is chosen to make the decision at each node:

                   <BlockMath math="IG(D_p, f) = H(D_p) - \sum_{j=1}^{m} \frac{|D_j|}{|D_p|} H(D_j)" />

                   where <InlineMath math="IG(D_p, f)" /> is the information gain of parent dataset <InlineMath math="D_p" /> by 
                   feature <InlineMath math="f" />, <InlineMath math="H(D_p)" /> is the entropy of the parent 
                   dataset, <InlineMath math="D_j" /> is the jth subset created from the split, and <InlineMath math="m" /> is the 
                   number of groups created from the split. Essentially, we will use some splitting criteria (e.g. everyone over a 
                   certain age or male/female, etc.), and we will measure whether the split datasets have become more or less 
                   "chaotic" -- in this case, Information Gain essentially refers to whether or not we can be more confident about 
                   which class everyone belongs to after the split vs. before the split within the splits. Very "pure" groups are good i.e., groups that 
                   have observations all belonging to the same class. 

                   

                   <figure className="flex-container-caption">
                    <div className="flex-container"><img src={decisiontree} alt="Broken" className="image-medium"/></div>
                        <figcaption>This is an example of the simple rule-based approach that decision trees take to provide outcomes. In this example, we first 
                            check to see whether or not the particular observation is over 30 or not. If yes, then they should travel down the left side, otherwise 
                            the observation goes right. Then, depending on what was decided as the next best splitting criteria, another decision is made. Remember that the 
                            splitting criteria is decided using entropy i.e. looking at what, of all possible splits, results in the greatest separations (or more purity)
                            across the splits; <a href="https://iprathore71.medium.com/complete-guide-to-decision-tree-cee0238128d" target="_blank" rel="noopener noreferrer">image source</a>.</figcaption>
                        </figure>

                   This process of selecting the best attribute and splitting the dataset is repeated recursively until one of the stopping 
                   criteria is met. These criteria can include a maximum depth of the tree, a minimum number of samples required to split 
                   an internal node (nodes here are just the groups in each split), or a minimum number of samples required to be at a leaf node (a leaf node is one of the groups after a split).

                </p>
                

                
                <h4>Random Forests</h4>
                <p className="subsubsection-paragraph">
                Random Forests build upon the concept of decision trees, but they introduce randomness into the tree construction to create a forest of 
                diverse trees. The randomness comes from two main sources:

                <ul>
                    <li>Each tree is trained on a random sample of the data (bootstrap sample).</li>
                    <li>At each split in the decision tree, a random subset of the features is considered.</li>
                </ul>

                This randomness helps to make the model more robust and less prone to overfitting compared to a single decision tree.
                Random Forests use a technique called bootstrap aggregating, or bagging, where multiple decision trees are trained on 
                bootstrapped samples of the training dataset. A bootstrapped sample is a sample taken with replacement from the original 
                dataset, of the same size as the original. This process is represented by:

                <BlockMath math="D_i \sim \text{Bootstrap}(D)" />

                where <InlineMath math="D_i" /> is the bootstrapped dataset for the <InlineMath math="i^{th}" /> tree 
                and <InlineMath math="D" /> is the original dataset. At each split in the construction of a tree, instead of considering all features, Random Forests randomly select a subset of the features. This number is typically much smaller than the total number of features, and it's a key
                 hyperparameter for the model. This process introduces more diversity among the trees, contributing to a more robust overall model.
                 The prediction of a Random Forest is made by aggregating the predictions of all the individual trees. This aggregation is typically
                  done by majority voting for classification tasks and by averaging for regression tasks. The formula for the final prediction in 
                  classification can be represented as:

                  <BlockMath math="\hat{y} = \text{mode}\{y_1, y_2, \ldots, y_n\}" />

                  where <InlineMath math="\hat{y}" /> is the predicted class, and <InlineMath math="y_1, y_2, \ldots, y_n" /> are the predictions from each 
                  of the <InlineMath math="n" /> trees in the forest. For regression, the formula is:

                  <BlockMath math="\hat{y} = \frac{1}{n} \sum_{i=1}^{n} y_i" />

                  where <InlineMath math="\hat{y}" /> is the predicted value, and <InlineMath math="y_1, y_2, \ldots, y_n" /> are the predictions from each tree.

                  In general, Random Forests have several advantages over regular decision trees:

                  <ul>
                        <li>They can handle both classification and regression tasks.</li>
                        <li>They are effective for high-dimensional datasets.</li>
                        <li>They provide a measure of feature importance.</li>
                        <li>They are less prone to overfitting than individual decision trees.</li>
                    </ul>

                    Let's now talk through an example for an NLP problem using Random Forests with the objective being 
                    sentiment analysis. The first step involves cleaning and preparing the text data. This might include removing punctuation, converting text to
                     lowercase, removing stop words, and stemming or lemmatization. After preprocessing, the text is converted into a numerical 
                     format. We get this conversion via TF-IDF (see the prior section for an explanation). After transforming the text documents into a TF-IDF matrix,
                      a Random Forest classifier is trained on this numerical data. The classifier uses multiple decision trees, each trained on a 
                      random subset of the training data and features, to make its predictions. The final sentiment prediction for a new document is determined by
                       aggregating the predictions from all the trees in the forest, typically using majority voting for classification tasks.

                       Applying Random Forests to NLP tasks like sentiment analysis offers several benefits:

                       <ul>
                            <li>Ability to handle high-dimensional data, common in text applications.</li>
                            <li>Robustness to overfitting, given the ensemble approach.</li>
                            <li>Flexibility in dealing with both binary and multi-class classification tasks.</li>
                        </ul>

                        This approach, combining TF-IDF for feature extraction and Random Forests for classification, provides a 
                        powerful tool for tackling NLP problems by leveraging the strengths of ensemble learning and the expressiveness 
                        of textual data.
                </p>
                
                <h4>Hyperparameters</h4>
                <p className="subsubsection-paragraph">
                <ul>
                    <li>
                    <strong>Number of Trees:</strong> The number of trees in the forest. A higher number increases model complexity and can lead to better performance but also increases computational cost and the risk of overfitting. In NLP, having more trees can be beneficial for capturing the nuances in high-dimensional text data, but it's essential to balance performance with efficiency.
                    </li>
                    <li>
                    <strong>Maximum Depth:</strong> The maximum depth of each tree. Deeper trees can model more complex patterns but might overfit the data. For NLP tasks, this parameter helps control the complexity of the hypotheses the model can learn from the text features.
                    </li>
                    <li>
                    <strong>Minimum Samples Split:</strong> The minimum number of samples required to split an internal node. Higher values prevent the model from learning too specific patterns, reducing overfitting. In NLP, this might affect the model's ability to learn about rare but informative terms in the text.
                    </li>
                    <li>
                    <strong>Minimum Samples Leaf:</strong> The minimum number of samples required to be at a leaf node. Setting this higher ensures that the model makes decisions based on more substantial evidence, which can be crucial for NLP to avoid overfitting on specific terms or phrases.
                    </li>
                    <li>
                    <strong>Maximum Features:</strong> The number of features to consider when looking for the best split. For NLP, where the feature space can be vast due to the large vocabulary, adjusting this parameter can significantly impact the model's performance and training time.
                    </li>
                    <li>
                    <strong>Bootstrap:</strong> Whether bootstrap samples are used when building trees. For NLP, using bootstrap sampling can introduce more diversity into the model, helping it generalize better across the varied language use in text data.
                    </li>
                    <li>
                    <strong>Class Weight:</strong> This parameter is used to weight the classes of the target variable differently to handle imbalanced classes, which is common in NLP tasks like sentiment analysis, where one sentiment might be more frequent than others.
                    </li>
                </ul>
                </p>

                <h4>In Code</h4>
                <p className="subsubsection-paragraph">
                    The following Python example demonstrates how 
                    to use a Random Forest for classifying text documents:
                    <SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
            {`from sklearn.datasets import fetch_20newsgroups
from sklearn.ensemble import RandomForestClassifier
from sklearn.feature_extraction.text import TfidfVectorizer
from sklearn.model_selection import train_test_split
from sklearn.metrics import accuracy_score

# Load the dataset
newsgroups_train = fetch_20newsgroups(subset='train', categories=['sci.space', 'rec.autos'])

# Sample text data and labels
texts = newsgroups_train.data
labels = newsgroups_train.target

# Convert texts to TF-IDF features
vectorizer = TfidfVectorizer()
X = vectorizer.fit_transform(texts)

# Split data into training and test sets
X_train, X_test, y_train, y_test = train_test_split(X, labels, test_size=0.3, random_state=42)

# Train a Random Forest classifier
rf_classifier = RandomForestClassifier(n_estimators=100)
rf_classifier.fit(X_train, y_train)

# Predict and evaluate
predictions = rf_classifier.predict(X_test)
accuracy = accuracy_score(y_test, predictions)

# Output the accuracy
print(f'Accuracy: {accuracy:.2f}')`}
                        </SyntaxHighlighter>
                    </p>
                </section>



                <section id="naive" className="code-cleaned">
                <h2>Naive Bayes</h2>
                <p className="subsubsection-paragraph">
                Naive Bayes classifiers are a family of simple probabilistic classifiers based on applying Bayes' theorem with 
                strong (naive) independence assumptions between the features. They are highly scalable and can quickly make 
                predictions even in large datasets, making them suitable for many NLP tasks.
                </p>

                <p className="subsubsection-paragraph">
                    <table style={{ width: '100%', borderCollapse: 'collapse', margin: '10px 0' }}>
                        <tbody>
                            <tr>
                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>Use Cases</td>
                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                                    <span style={{ color: '#333399' }}>Spam filtering</span>,
                                    <span style={{ color: '#008000' }}> Sentiment analysis</span>,
                                    <span style={{ color: '#ff4500' }}> Topic classification</span>,
                                    <span style={{ color: '#1e90ff' }}> Language detection</span>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>Python Libraries</td>
                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                                    <span style={{ color: '#6a5acd' }}>Scikit-learn (sklearn.naive_bayes)</span>,
                                    <span style={{ color: '#20b2aa' }}> NLTK (nltk.classify.NaiveBayesClassifier)</span>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>O-Complexity (Worst Case)</td>
                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                                    <span>O(nk)</span>, where <i>n</i> is the number of features and <i>k</i> is the number of data points
                                </td>
                            </tr>
                            <tr>
                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>Relevant Papers</td>
                                <td style={{ padding: '8px', border: '1px solid #ddd' }}>
                                    <span>"A Statistical Approach to Mechanized Encoding and Searching of Literary Information"</span> by M. E. Maron and J. L. Kuhns, 1960
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </p>


            
                <h4>Naive Bayes Foundations</h4>
                <p className="subsubsection-paragraph">
                <p>
                    At the bedrock of Naive Bayes is Bayes Theorem. We already discussed Bayes Theorem before but let's do a quick recap; the equation is:
                </p>

                <BlockMath math="P(C_k | x) = \frac{P(x | C_k) P(C_k)}{P(x)}" />

                <p>
                    where:
                    <ul>
                    <li><InlineMath math="P(C_k | x)" /> is the posterior probability of class <InlineMath math="C_k" /> given predictor <InlineMath math="x" />.</li>
                    <li><InlineMath math="P(C_k)" /> is the prior probability of class <InlineMath math="C_k" />.</li>
                    <li><InlineMath math="P(x | C_k)" /> is the likelihood which is the probability of predictor <InlineMath math="x" /> given class <InlineMath math="C_k" />.</li>
                    <li><InlineMath math="P(x)" /> is the prior probability of predictor <InlineMath math="x" />.</li>
                    </ul>
                </p>

                    In the Naive Bayes classifier, the independence assumption simplifies the computation of <InlineMath math="P(x | C_k)" />, as it allows us to express
                    this probability as the product of individual probabilities for each feature:

                <BlockMath math="P(x | C_k) = \prod_{i=1}^{n} P(x_i | C_k)" />

                
                where <InlineMath math="x_i" /> is a feature in the feature vector <InlineMath math="x" />, 
                and <InlineMath math="n" /> is the number of features. This assumption greatly simplifies the computation, 
                especially for high-dimensional data, as is typical in NLP tasks.
                

                
                For classification, we are interested in finding the class <InlineMath math="C_k" /> that maximizes the posterior 
                probability <InlineMath math="P(C_k | x)" />. This leads to the decision rule:
                

                <BlockMath math="\hat{y} = \underset{C_k}{\arg\max} \ P(C_k | x)" />

                
                    Since <InlineMath math="P(x)" /> is constant for all classes, we can focus on 
                    maximizing <InlineMath math="P(x | C_k) P(C_k)" /> instead. This simplification leads to the practical 
                    form of the Naive Bayes decision rule:
            

                <BlockMath math="\hat{y} = \underset{C_k}{\arg\max} \ \prod_{i=1}^{n} P(x_i | C_k) P(C_k)" />

        
                    In NLP, <InlineMath math="P(x_i | C_k)" /> can be computed for each word in the vocabulary from a training set,
                     and <InlineMath math="P(C_k)" /> can be estimated as the relative frequency of
                      class <InlineMath math="C_k" /> in the training set. This approach, despite its simplicity, often performs
                       well in text classification tasks, such as spam detection, sentiment analysis, and topic classification. 
                       Alternatively, you could assign a probability distribution to <InlineMath math="P(C_k)" /> -- feel free to 
                       look into this on you own!

                       
                    
                       <p>
                            If this seems to obscure at face value, let's do an example where we'll classify a document as "Spam" or "Not Spam" to clarify things. 
                            Let's assume our document contains the words {"{"} "win", "free" {"}"} and we have a simple
                              training dataset that our model has learned from.
                        </p>

                            We start with the prior probabilities of each class, which represent our initial belief about the 
                            distribution of classes; I am going to make these up but they could be just the proportion in your dataset 
                            of your text belonging to spam and not. So, if you had 10 emails and 4 were spam and 6 were not, you would get 
                            the following prior:
  
                        <BlockMath math="P(\text{{Spam}}) = 0.4" />
                        <BlockMath math="P(\text{{Not Spam}}) = 0.6" />


                            Next, we calculate the likelihood of observing each word in the document given each class. For 
                            simplicity, let's use the following probabilities (note that in practice, these would be calculated 
                            based on the training data):

                        <BlockMath math="P(\text{{win}} | \text{{Spam}}) = 0.7, \quad P(\text{{free}} | \text{{Spam}}) = 0.8" />
                        <BlockMath math="P(\text{{win}} | \text{{Not Spam}}) = 0.1, \quad P(\text{{free}} | \text{{Not Spam}}) = 0.3" />


                            We then calculate the posterior probability for each class by multiplying the prior and likelihoods for
                             all words in the document, using the Naive Bayes assumption that features (words) are independent given 
                             the class:

                        <BlockMath math="P(\text{{Spam}} | \text{{win, free}}) \propto P(\text{{Spam}}) \times P(\text{{win}} | \text{{Spam}}) \times P(\text{{free}} | \text{{Spam}})" />
                        <BlockMath math="P(\text{{Not Spam}} | \text{{win, free}}) \propto P(\text{{Not Spam}}) \times P(\text{{win}} | \text{{Not Spam}}) \times P(\text{{free}} | \text{{Not Spam}})" />

                
                            These calculations give us a measure of how likely the document is to be "Spam" or "Not Spam" based on 
                            its content. The class with the higher posterior probability is chosen as the classification for the 
                            document.

        
                            If <InlineMath math="P(\text{{Spam}} | \text{{win, free}}) > P(\text{{Not Spam}} | \text{{win, free}})" />,
                             then the document is classified as "Spam". Otherwise, it's classified as "Not Spam".


                            In practice, to avoid numerical underflow from multiplying many small probabilities, we would use the log


                </p>

                <h4>Hyperparameters</h4>
                <p className="subsubsection-paragraph">

                <ul>
                    <li>
                    <strong>Type of Naive Bayes:</strong> The choice between Gaussian, Multinomial, and Bernoulli Naive Bayes depends 
                    on the data distribution. For NLP, Multinomial and Bernoulli are commonly used, depending on whether the feature
                     vectors represent the frequency of words or merely their presence/absence.
                    </li>
                    <li>
                    <strong>Alpha (α) - Laplace Smoothing:</strong> This parameter is crucial in handling zero probabilities in the 
                    dataset. It adds a small value (α) to the count of each word in the likelihood estimation, ensuring no probability 
                    is zero. Commonly set to 1 (add-one smoothing), but can be adjusted based on validation performance.
                    <br />
                    <BlockMath math="\text{{Adjusted Count}} = \frac{{\text{{Count of word}} + \alpha}}{{\text{{Total words}} + \alpha \times \text{{Vocabulary size}}}}" />
                    
                    </li>
                    <li>
                    <strong>Fit Prior:</strong> This boolean parameter determines whether to learn class prior probabilities or use a 
                    uniform prior. Not learning the prior can be useful if the classes are known to be balanced.
                    </li>
                    <li>
                    <strong>Class Prior:</strong> If the fit prior is false, this parameter allows manually setting the prior 
                    probabilities for the classes. This can be useful in cases of imbalanced datasets where the class distribution
                     in the training set might not reflect the real-world scenario.
                    </li>
                </ul>
                </p>

                <h4>In Code</h4>
                <p className="subsubsection-paragraph">
                    Here's a Python example using Naive Bayes 
                    for text classification:
                    <SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
            {`from sklearn.datasets import fetch_20newsgroups
from sklearn.feature_extraction.text import CountVectorizer
from sklearn.model_selection import train_test_split
from sklearn.naive_bayes import MultinomialNB
from sklearn.metrics import accuracy_score

# Load the dataset
newsgroups_train = fetch_20newsgroups(subset='train', categories=['sci.space', 'talk.politics.misc'])

# Sample text data and labels
texts = newsgroups_train.data
labels = newsgroups_train.target

# Convert texts to word count vectors
vectorizer = CountVectorizer()
X = vectorizer.fit_transform(texts)

# Split data into training and test sets
X_train, X_test, y_train, y_test = train_test_split(X, labels, test_size=0.3, random_state=42)

# Train a Naive Bayes classifier
nb_clf = MultinomialNB()
nb_clf.fit(X_train, y_train)

# Predict and evaluate
predictions = nb_clf.predict(X_test)
accuracy = accuracy_score(y_test, predictions)

# Output the accuracy
print(f'Accuracy: {accuracy:.2f}')`}
                        </SyntaxHighlighter>
                    </p>

                </section>


                
                
                <div className="subsubsection-navigation">
                    <Link to="/ml">← Machine Learning</Link>
                    <Link to="/ml/classic">Classic NLP Models →</Link>
                </div>
            </main>
            
            <Footer />
        </div>
    );
}

export default OGML;
