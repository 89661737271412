import React from 'react';
import '../../styles/main.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

function Main() {
    return (
        <div className="App">
            <Header />
            
            <main className="main-content">

                <p className="intro-paragraph">Learn NLP</p>
                <div className="section-links">
                    <a href="/begin">Begin</a>
                    <a href="/foundations">Foundations</a>
                    <a href="/nlpbasics">NLP Basics</a>
                    <a href="/ml">Machine Learning</a>
                    <a href="/llms">Large Language Models</a>
                    <a href="/existingmodels">Current Standard</a>
                    <a href="/frontier">Frontier</a>
                </div>

                <div className="section-links-2">
                    <a href="/about">About</a>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default Main;