import React from 'react';
import '../../styles/subsection.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import 'katex/dist/katex.min.css';
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function Reformers() {
    return (
        <div className="subsubsection-container">
            <Header />
            <div class="side-nav-container">
                <aside className="subsubsection-side-nav">
                    <a href="#1">Concept</a>
                    <a href="#2">Code</a>
                </aside>
            </div>
            
            <main className="subsubsection-content">
            <div className="titles"><h1>Reformer</h1></div>

            <section id="1" className="code-cleaned">
                <h2>Concept</h2>
                    <p className="subsubsection-paragraph">
                    Reformer is a unique twist on the Transformer model, designed to tackle some of the challenges that come with processing very long sequences of data, such as entire books or comprehensive
                     documents. Traditional Transformer models are incredibly powerful, but they have a bit of an appetite for memory, especially when dealing with
                      long texts. This is because they pay attention to every single word in a sentence, no matter how long it is, to understand its context (barring masking). Imagine trying to keep track of every conversation
                       in a crowded room; it's a taxing operation. That's the challenge Reformer aims to address.</p>

                        <p className="subsubsection-paragraph">
                        The clever trick Reformer uses is akin to making friends in a huge, bustling party. Instead of trying to listen to everyone at once, which is overwhelming, you'd likely talk with a few 
                        people who share your interests. Reformer does something similar with words in a text. It uses a technique called "locality-sensitive hashing" to group similar words together, so it 
                        only needs to pay close attention to words that are related or "close" in meaning, rather than every word in the text. This approach significantly cuts down on the memory and 
                        computational power needed, making it much more efficient, especially for long texts.
                        </p>

                        <p className="subsubsection-paragraph">
                        You can read more about this approach in: "Reformer: The Efficient Transformer," authored by Nikita Kitaev, Łukasz Kaiser, and Anselm Levskaya
                        </p>

                        </section>

            <section id="2" className="code-cleaned">
            <h2>In Code</h2>
                    <p className="subsubsection-paragraph">
                    
<SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
{`from transformers import ReformerModel, ReformerTokenizer

# Load pre-trained model and tokenizer
model_name = "google/reformer-enwik8"
model = ReformerModel.from_pretrained(model_name)
tokenizer = ReformerTokenizer.from_pretrained(model_name)

# Generate text
input_text = "Reformer is known for its efficiency."
input_ids = tokenizer(input_text, return_tensors="pt").input_ids
output = model.generate(input_ids)
generated_text = tokenizer.decode(output[0], skip_special_tokens=True)
print(generated_text)
`}
</SyntaxHighlighter>
</p>
                
            </section>

            <div className="subsubsection-navigation">
                <Link to="/frontier/prophetnet">← ProphetNet</Link>
                <Link to="/frontier/electra">ELECTRA →</Link>
            </div>
        </main>
            
            <Footer />
        </div>
    );
}

export default Reformers;
