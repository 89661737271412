import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import '../../styles/section.css'; // Import the shared CSS file for sections
import Header from '../../components/Header';
import Footer from '../../components/Footer';

function Begin() {
    return (
        <div className="section-container">
            <Header />
            <main className="section-content">
                <p className="section-intro-paragraph">Learn about the history of NLP</p>
                <div className="subsection-links">
                    <Link to="/begin/whatisit">What is NLP?</Link>
                    <Link to="/begin/history">History</Link>
                    <Link to="/begin/structure">Structure</Link>
                </div>
                <Outlet />
            </main>
            <Footer />
        </div>
    );
}

export default Begin;
