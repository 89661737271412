import React from 'react';
import '../../styles/subsection.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

function Calculus() {
    return (
        <div className="subsubsection-container">
            <Header />
            <div class="side-nav-container">
                <aside className="subsubsection-side-nav">
                    <a href="#calcfoundations">Essentials</a>
                    <a href="#optimization">Optimization</a>
                    <a href="#other">Other</a>
                </aside>
            </div>
            
            <main className="subsubsection-content">
                <div className="titles"><h1>Calculus</h1></div>

                <section id="calcintro" className="code-cleaned">

                    <p className="subsubsection-paragraph">
                    Calculus, a branch of mathematics that studies continuous change, is a powerful tool for understanding and modeling various phenomena.
                    Calculus involves two fundamental concepts: differentiation, which is concerned with rates of change, and integration, which deals 
                    with the accumulation of quantities. These concepts offer a mathematical framework for dealing with continuous variables and functions, making them very useful in many 
                    fields, including computer science, engineering, economics, and, notably, NLP.
                    </p>

                    <p className="subsubsection-paragraph">
                        In NLP, calculus plays a pivotal role in several areas. Foremost, many machine learning algorithms 
                        rely heavily on calculus for optimization. Techniques such as gradient descent, used for training models like neural networks, are grounded in differential calculus. 
                        These models often include continuous parameters that need to be optimized to improve their performance in tasks like text classification, sentiment analysis, or machine 
                        translation.
                    </p>
                    <p className="subsubsection-paragraph">
                        Additionally, calculus is integral to the development of algorithms for speech recognition and synthesis, as well as for the analysis of linguistic patterns over time. 
                        The concept of a derivative, for instance, is used to model the rate of change in speech or text features, enabling the identification of nuances in speech and linguistic 
                        trends. Also, concepts from integral calculus are employed in probabilistic modeling and information theory, both of which can be critical 
                        for understanding and generating human language in a computational setting.
                    </p>
                    <p className="subsubsection-paragraph">
                        Just like the previous section, next few are largely a refresher and some of it will not be directly relevant -- I was hoping to make this website 
                        exhaustive so there will be concepts discussed that you might already be familiar with; feel free to skip them if they seem too basic but I will try to provide the NLP 
                        context for them.
                    </p>

                    </section>

                    <section id="calcfoundations" className="code-cleaned">

                    <h4>Limits</h4>
                    <p className="subsubsection-paragraph">
                        A limit is a fundamental concept in calculus that describes the behavior of a function as its argument approaches a particular value. Limits 
                        help in understanding the behavior of functions at points where they are not explicitly defined or where they exhibit interesting behavior, such as discontinuities.
                    </p>

                    <p className="subsubsection-paragraph">
                        The limit of a function <InlineMath math="f(x)" /> as <InlineMath math="x" /> approaches a value <InlineMath math="a" /> is the value that <InlineMath math="f(x)" /> gets closer to as <InlineMath math="x" /> gets closer to <InlineMath math="a" />. Mathematically, it is denoted as:
                        <BlockMath math="\lim_{{x \to a}} f(x) = L" />
                        where <InlineMath math="L" /> is the value that <InlineMath math="f(x)" /> approaches as <InlineMath math="x" /> approaches <InlineMath math="a" />.
                    </p>

                    <p className="subsubsection-paragraph">
                        As an example, consider proving the limit:
                        <BlockMath math="\lim_{{x \to 2}} (3x - 5) = 1" />
                        This can be shown by directly substituting <InlineMath math="x = 2" /> into the function <InlineMath math="f(x) = 3x - 5" />, which yields <InlineMath math="3(2) - 5 = 1" />.
                    </p>

                    <p className="subsubsection-paragraph">
                        In NLP, the concept of limits is more abstract and theoretical rather than direct. Limits can be relevant in understanding the behavior of algorithms and models as 
                        certain parameters approach specific values. For instance, in machine learning models used in NLP, examining how the performance of a model changes as a parameter 
                        approaches a limit can provide insights into the stability and robustness of the model -- remember that models themselves are largely just functions varying in 
                        complexity. Additionally, in probabilistic models and information theory, limits are used to define and understand concepts like entropy and convergence of sequences of random variables. Though not often explicitly calculated 
                        in practical NLP applications, the theoretical framework provided by limits underpins many fundamental concepts in algorithm design and analysis in the field.
                    </p>

                    <h4>Derivatives</h4>
                    <p className="subsubsection-paragraph">
                        Derivatives are a fundamental concept in calculus, representing the rate at which a function changes at any given point. In simple terms, the derivative tells us how 
                        a function's output value changes as its input changes. This concept is crucial in various fields, including physics, various types of engineering, and of course, NLP.
                    </p>

                    <p className="subsubsection-paragraph">
                        Mathematically, the derivative of a function <InlineMath math="f(x)" /> at a point <InlineMath math="x" /> is denoted as <InlineMath math="f'(x)" /> or <InlineMath math="\frac{df}{dx}" />, and 
                        it represents the slope of the tangent line to the function at that point. For example:
                        <BlockMath math="f(x) = x^2" />
                        <BlockMath math="f'(x) = 2x" />
                        In this example, the derivative of <InlineMath math="f(x) = x^2" /> is <InlineMath math="f'(x) = 2x" />, indicating that the slope of the function <InlineMath math="f(x)" /> at 
                        any point <InlineMath math="x" /> is <InlineMath math="2x" />.
                    </p>

                    <p className="subsubsection-paragraph">
                        Another example is the function <InlineMath math="g(x) = \sin(x)" />, where:
                        <BlockMath math="g'(x) = \cos(x)" />
                        This shows that the rate of change of the sine function is represented by the cosine function.
                    </p>

                    <p className="subsubsection-paragraph">
                        Derivatives play a crucial role in training machine learning models, particularly neural networks. The training process involves optimizing a loss 
                        function, which measures how well the model's predictions match the actual data. The optimization is typically performed using gradient descent, where derivatives 
                        indicate the direction in which the model's parameters should be adjusted to minimize the loss function.
                    </p>

                    <p className="subsubsection-paragraph">
                        For example, consider a simple neural network with a weight <InlineMath math="w" />, input <InlineMath math="x" />, and loss function <InlineMath math="L" />. The derivative 
                        of the loss function with respect to the weight <InlineMath math="\frac{dL}{dw}" /> guides how to update the weight to reduce the loss. In mathematical terms, 
                        if the network's output is <InlineMath math="y = wx" />, and the loss function is mean squared error, then:
                        <BlockMath math="L = (y_{\text{actual}} - y_{\text{predicted}})^2 = (y_{\text{actual}} - wx)^2" />
                        <BlockMath math="\frac{dL}{dw} = -2x(y_{\text{actual}} - wx)" />
                        This derivative, also known as the gradient, quantifies the rate of change of the loss with respect to the weight <InlineMath math="w" />. It indicates the direction and 
                        magnitude to adjust the weight to minimize the loss. If the derivative is positive, decreasing the weight leads to a decrease in the loss. Conversely, if the derivative 
                        is negative, increasing the weight will decrease the loss. Gradient descent uses this principle to adjust <InlineMath math="w" /> iteratively, taking steps proportional to 
                        the negative of the gradient. The size of these steps is controlled by a parameter called the learning rate.
                    </p>

                    <p className="subsubsection-paragraph">
                        By repeatedly computing the derivative and adjusting the weights in the opposite direction of the gradient, the neural network 'descends' along the loss function's surface towards the minimum loss, hence the term 'gradient descent'. This process continues iteratively until the loss is minimized or reaches an acceptable level, indicating that the model's predictions are as close as possible to the actual values.
                    </p>

                    <p className="subsubsection-paragraph">
                        Derivatives are central to backpropagation, an algorithm used for training neural networks. Backpropagation computes the gradient of the loss function with respect 
                        to each weight by the chain rule, which is a fundamental rule in calculus used to compute derivatives of composite functions. This process involves taking derivatives at 
                        each layer of the network and propagating the error backward from the output to the input layer, enabling effective training of deep neural networks for complex NLP tasks.
                    </p>

                    <h4>Integrals</h4>
                    <p className="subsubsection-paragraph">
                        Integrals are a key concept in calculus, representing the accumulation or aggregation of quantities over a particular interval. While derivatives focus on rates of change, 
                        integrals deal with the total size or value, such as areas under curves and accumulated quantities. In NLP, integrals find their significance in probabilistic modeling
                         and analysis of continuous data.
                    </p>

                    <p className="subsubsection-paragraph">
                        The integral of a function <InlineMath math="f(x)" /> over an interval <InlineMath math="[a, b]" /> is denoted as <InlineMath math="\int_{a}^{b} f(x) \, dx" /> and represents the area under the curve of <InlineMath math="f(x)" /> from <InlineMath math="a" /> to <InlineMath math="b" />. For example, the integral of <InlineMath math="f(x) = x^2" /> from 0 to 2 is:
                        <BlockMath math="\int_{0}^{2} x^2 \, dx" />
                        which equals 8/3, representing the area under the curve of <InlineMath math="x^2" /> between 0 and 2.
                    </p>

                    <p className="subsubsection-paragraph">
                        Integrals are particularly relevant in probabilistic models and continuous data analysis. For instance, when dealing with continuous probability 
                        distributions in topics such as latent semantic analysis or continuous speech recognition, integrals are used to calculate probabilities and expectations. They are 
                        essential in understanding the underlying probability distributions of word occurrences and usage patterns in natural language.
                    </p>

                    <p className="subsubsection-paragraph">
                        Integrals also play a role in continuous optimization problems in machine learning algorithms used in NLP. They are involved in the computation of gradients and 
                        in techniques like Expectation-Maximization (EM) algorithm, which is used for parameter estimation in probabilistic models. In these applications, integrals help to 
                        aggregate information over continuous variables, providing a comprehensive view of the data's characteristics and guiding the optimization process in machine learning models.
                    </p>

                    <h4>Taylor Expansions</h4>

                    <p className="subsubsection-paragraph">
                        Taylor expansions are a powerful tool used to approximate complex functions with polynomials. They are particularly useful in situations where the 
                        function itself is too complicated to analyze directly but can be approximated locally by a simpler function. They don't have a super explicit use in NLP if you are 
                        just using libraries but they are used in the core of many of those libraries.
                    </p>

                    <p className="subsubsection-paragraph">
                        A Taylor series expansion of a function <InlineMath math="f(x)" /> about a point <InlineMath math="a" /> is given by:
                        <div className="custom-math-size"><BlockMath math="f(x) = f(a) + f'(a)(x - a) + \frac{f''(a)}{2!}(x - a)^2 + \frac{f'''(a)}{3!}(x - a)^3 + \ldots" /></div>
                        This series approximates <InlineMath math="f(x)" /> by summing its derivatives at <InlineMath math="a" />, each multiplied by a factor 
                        of <InlineMath math="(x - a)^n / n!" />.
                    </p>

                    <p className="subsubsection-paragraph">
                        As an example, let's approximate <InlineMath math="e^x" /> near <InlineMath math="x = 0" /> (i.e., <InlineMath math="a = 0" />). The derivatives of <InlineMath math="e^x" /> at <InlineMath math="x = 0" /> are all 1, so its Taylor series expansion is:
                        <BlockMath math="e^x = 1 + x + \frac{x^2}{2!} + \frac{x^3}{3!} + \ldots" />
                        Here, each term adds more accuracy to the approximation. For small values of <InlineMath math="x" />, the first few terms can provide a good approximation of <InlineMath math="e^x" />.
                    </p>

                    <p className="subsubsection-paragraph">
                        In NLP, Taylor expansions are used in several ways, particularly in the context of algorithm development and model optimization. For instance, many machine learning models
                         used in NLP, like neural networks, involve non-linear functions. During training and optimization, Taylor expansions can approximate these non-linear functions around a 
                         current point, simplifying calculations and providing insights into the behavior of the function. For example, in the training of neural networks, the optimization algorithms often need to understand how changes in the weights and biases will affect the output. Taylor 
                        expansions can be used to approximate the effect of small changes on the network's output, which is essential for gradient-based optimization methods like gradient descent.
                         This is especially true in complex models where exact calculations are computationally infeasible, and approximations are necessary to efficiently update model parameters.
                    </p>

                    <h4>Multivariate Calculus</h4>
                    <p className="subsubsection-paragraph">
                        Multivariate calculus extends the concepts of calculus to functions of several variables. It's crucial in fields that deal with high-dimensional data and complex models, 
                        such as NLP, where most problems involve multiple variables and parameters.
                    </p>

                    <p className="subsubsection-paragraph">
                        <b>Limits in Multivariable Calculus:</b> Limits for multivariate functions consider how the function behaves as the input variables approach certain values. For 
                        instance, the limit of <InlineMath math="f(x, y)" /> as <InlineMath math="(x, y) \to (a, b)" />.
                    </p>

                    <p className="subsubsection-paragraph">
                        <b>Derivatives (Partial and Gradient):</b> In multivariable calculus, the derivative becomes a partial derivative when considering functions of multiple variables. 
                        The gradient, a vector of partial derivatives, indicates the direction of steepest ascent in a multivariable function. For a function <InlineMath math="f(x, y)" />, the
                         gradient is <InlineMath math="\nabla f = \left( \frac{\partial f}{\partial x}, \frac{\partial f}{\partial y} \right)" />.
                    </p>

                    <p className="subsubsection-paragraph">
                        <b>Integrals:</b> Multivariable integrals extend to multiple dimensions, such as double or triple integrals. For example, the double 
                        integral <InlineMath math="\int \!\! \int_{D} f(x, y) \, dx \, dy" /> represents the volume under the surface <InlineMath math="f(x, y)" /> over 
                        the domain <InlineMath math="D" />.
                    </p>

                    <p className="subsubsection-paragraph">
                        <b>Taylor Expansions:</b> In multiple variables, Taylor expansions approximate functions near a point using polynomials in several variables. For a two-variable
                         function <InlineMath math="f(x, y)" />, its Taylor expansion around <InlineMath math="(a, b)" /> includes terms 
                         like <InlineMath math="\frac{\partial f}{\partial x}(a, b)(x - a)" /> and <InlineMath math="\frac{\partial^2 f}{\partial x \partial y}(a, b)(x - a)(y - b)" />.
                    </p>

                    <p className="subsubsection-paragraph">
                        Multivariate calculus is what is actually used in NLP due to the high-dimensional nature of linguistic data and models. Most models, such as neural networks, involve 
                        multiple parameters and variables, making the understanding of multivariate functions essential. For example, when optimizing a neural network for a task like language 
                        translation, the gradient (a multivariable derivative) is used to update each weight in the network, requiring calculations across multiple dimensions. You could even think 
                        about the case of regression -- we try and find multiple parameters (<InlineMath math="\vec{\beta}" /> ), and these are found by taking partial derivatives.
                    </p>

                </section>
                
                <section id="optimization" className="code-cleaned">
                    <h2>Optimization</h2>
                    <p className="subsubsection-paragraph"></p>

                    <h4>Gradient Descent</h4>
                    <p className="subsubsection-paragraph">
                        Gradient descent is a fundamental optimization algorithm used extensively in machine learning, including NLP, to minimize a function. It's particularly effective in 
                        scenarios where the objective function is complex and has many parameters, as is common in NLP models.
                    </p>

                    <p className="subsubsection-paragraph">
                        Gradient descent optimizes a function by iteratively moving in the direction of the steepest descent as defined by the negative of the gradient. For a function <InlineMath math="f(\theta)" />, where <InlineMath math="\theta" /> represents the parameters of the model, the gradient descent update rule is:
                        <BlockMath math="\theta_{\text{new}} = \theta_{\text{old}} - \alpha \nabla f(\theta_{\text{old}})" />
                        Here, <InlineMath math="\alpha" /> is the learning rate, a hyperparameter that determines the step size at each iteration, 
                        and <InlineMath math="\nabla f(\theta_{\text{old}})" /> is the gradient of the function at the current parameter values. Essentially, we take the partial derivatives 
                        with respect to every parameter in the function and add/subtract that from the old values of those parameters in accordance to the learn rate. The reason we do it 
                        in accordance to this learn rate is because we may have a complex non-linear function and it would be nearly impossible to find a global minimum right away; instead, we slowly 
                        move in the direction of the negative gradient. You will see more about this in the neural network section. 
                    </p>

                    <p className="subsubsection-paragraph">
                        For example, consider a simple linear regression model where the objective is to minimize the mean squared error (MSE) function:
                        <BlockMath math="f(\theta) = \frac{1}{n} \sum_{i=1}^{n} (y_i - (mx_i + b))^2" />
                        In this case, <InlineMath math="\theta" /> consists of the slope <InlineMath math="m" /> and intercept <InlineMath math="b" />, and the 
                        gradient descent updates for <InlineMath math="m" /> and <InlineMath math="b" /> would involve the partial derivatives of the MSE with respect to each.
                    </p>

                    <p className="subsubsection-paragraph">
                        Gradient descent is pivotal in NLP for training various models, especially deep neural networks. These networks typically have a large number of parameters and are 
                        trained on tasks like text classification, language modeling, and machine translation. The optimization of these models involves adjusting parameters to minimize a 
                        loss function, which quantifies the difference between the predicted and actual outcomes.
                    </p>

                    <p className="subsubsection-paragraph">
                        For instance, in a neural network used for sentiment analysis, the parameters are the weights and biases of the network, and the loss function might be cross-entropy 
                        loss. Gradient descent is used to compute the gradient of this loss function with respect to all the weights and biases, and then iteratively adjust these parameters 
                        to minimize the loss (similar to the equation above but the derivative would look different as the cost function is different).
                    </p>


                    <p className="subsubsection-paragraph">
                        Advanced variations of gradient descent, like stochastic gradient descent (SGD), Adam, and RMSprop, are often used in NLP. These variations address some of the challenges of 
                        basic gradient descent, such as slow convergence and sensitivity to learning rate, making them more suitable for large-scale NLP tasks -- again, more on this later. 
                    </p>

                    <h4>Minimizers</h4>
                    <p className="subsubsection-paragraph">
                        In machine learning, optimization minimizers are algorithms used to minimize a loss function, thereby improving the model's performance. Various types of minimizers 
                        are employed, each with its strengths and ideal use cases. I provide a short summary of them for now and more details will be given as they are used in later sections. 
                    </p>
                    
                    <p className="subsubsection-paragraph">
                        <b>1. Batch Gradient Descent:</b> This algorithm computes the gradient of the loss function for the entire dataset to perform a single update. It is precise but can be 
                        very slow and computationally expensive, especially for large datasets.
                    </p>

                    <p className="subsubsection-paragraph">
                        <b>2. Stochastic Gradient Descent (SGD):</b> Unlike batch gradient descent, SGD updates the parameters for each training example. It is much faster and can be used for 
                        online learning, but it can be noisier than the batch version.
                        <BlockMath math="\theta = \theta - \alpha \nabla f(\theta; x^{(i)}, y^{(i)})" />
                        Here, <InlineMath math="\theta" /> is updated for each training instance <InlineMath math="(x^{(i)}, y^{(i)})" />.
                    </p>

                    <p className="subsubsection-paragraph">
                        <b>3. Mini-batch Gradient Descent:</b> This is a compromise between batch and stochastic versions. It updates the parameters after computing the gradient on small batches. 
                        It balances the efficiency of batch processing and the speed of SGD.
                    </p>

                    <p className="subsubsection-paragraph">
                        <b>4. Momentum:</b> Momentum optimization is a variation of SGD that takes into account the previous step to smooth out the updates. It helps accelerate SGD in the relevant 
                        direction and dampens oscillations i.e., it looks to see where most/least improvement is occuring with respect to loss and adjusts movements.
                        <BlockMath math="v = \beta v - \alpha \nabla f(\theta)" />
                        <BlockMath math="\theta = \theta + v" />
                        Here, <InlineMath math="v" /> is the velocity vector, and <InlineMath math="\beta" /> is the momentum coefficient.
                    </p>

                    <p className="subsubsection-paragraph">
                        <b>5. Adam (Adaptive Moment Estimation):</b> Adam combines ideas from momentum and RMSprop (another optimizer that adapts the learning rate). It calculates adaptive 
                        learning rates for each parameter.
                    </p>

                    <p className="subsubsection-paragraph">
                        <b>6. RMSprop:</b> RMSprop adapts the learning rate for each of the parameters, dividing the learning rate for a weight by a running average of the magnitudes of recent 
                        gradients for that weight.
                    </p>

                    <p className="subsubsection-paragraph">
                        The choice of optimizer can significantly impact the performance of models. For example, neural networks often have a large number of parameters, and efficient 
                        optimization is key to training them effectively. In NLP, where models deal with high-dimensional input spaces, choosing the 
                        right minimizer can lead to faster convergence, better generalization, and improved handling of local minima. Advanced optimizers like Adam and RMSprop are widely used in
                         training deep learning models for NLP tasks due to their efficiency and robustness in handling complex optimization landscapes inherent in these models.
                    </p>

                    <h4>Hessians</h4>

                    <p className="subsubsection-paragraph">
                        The Hessian matrix is a critical concept in optimization, particularly in the context of machine learning and NLP. It represents the second-order partial derivatives of a 
                        function and provides insights into the curvature of the function's graph, which is essential for understanding the behavior of optimization algorithms.
                    </p>

                    <p className="subsubsection-paragraph">
                        The Hessian matrix of a function <InlineMath math="f(x_1, x_2, ..., x_n)" />, which is a scalar-valued function of several variables, is defined as:
                        <BlockMath math="H(f) = \begin{bmatrix} 
                            \frac{\partial^2 f}{\partial x_1^2} & \frac{\partial^2 f}{\partial x_1 \partial x_2} & \cdots & \frac{\partial^2 f}{\partial x_1 \partial x_n} \\
                            \frac{\partial^2 f}{\partial x_2 \partial x_1} & \frac{\partial^2 f}{\partial x_2^2} & \cdots & \frac{\partial^2 f}{\partial x_2 \partial x_n} \\
                            \vdots & \vdots & \ddots & \vdots \\
                            \frac{\partial^2 f}{\partial x_n \partial x_1} & \frac{\partial^2 f}{\partial x_n \partial x_2} & \cdots & \frac{\partial^2 f}{\partial x_n^2}
                        \end{bmatrix}" />
                        This matrix gives us valuable information about the local curvature of the function, which is instrumental in understanding the nature of local minima, maxima, and saddle points.
                    </p>

                    <p className="subsubsection-paragraph">
                        Understanding the Hessian matrix is crucial when trying to develop optimized models. The curvature information provided by the Hessian can help in determining the most 
                        effective optimization strategies. For instance:
                        <ul>
                            <li><b>Model Training:</b> In training deep learning models for tasks like language modeling or sentiment analysis, the Hessian can inform adjustments to learning rates or 
                            indicate the presence of saddle points that might slow down training.</li>
                            <li><b>Algorithm Selection:</b> Algorithms that incorporate second-order information (like Newton's method) can potentially offer faster convergence compared to first-order 
                            methods like gradient descent, especially in scenarios where the loss surface is poorly conditioned.</li>
                            <li><b>Regularization and Generalization:</b> Analysis of the Hessian can also contribute to understanding how different regularization techniques affect a model's ability 
                            to generalize from training to unseen data.</li>
                        </ul>
                        However, directly computing and utilizing the Hessian in NLP can be computationally expensive due to the high dimensionality of the models. As such, approximate methods or 
                        algorithms that implicitly leverage curvature information are often used.
                    </p>

                    <h4>Lagrangians</h4>

                    <p className="subsubsection-paragraph">
                        The Lagrangian is a mathematical tool used in optimization, particularly in problems with constraints. It extends optimization by incorporating 
                        constraints directly into the optimization process, allowing for the solution of more complex problems that are common in various fields, including NLP.
                    </p>

                    <p className="subsubsection-paragraph">
                        The general form of a problem is to maximize or minimize a 
                        function <InlineMath math="f(x)" />, subject to constraints <InlineMath math="g_i(x) = 0" />. The Lagrangian <InlineMath math="\mathcal{L}" /> is defined as:
                        <BlockMath math="\mathcal{L}(x, \lambda) = f(x) - \sum_{i}\lambda_i g_i(x)" />
                        Here, <InlineMath math="x" /> represents the variables to be optimized, <InlineMath math="\lambda_i" /> are the Lagrange multipliers, 
                        and <InlineMath math="g_i(x)" /> are the constraint functions. The solutions are found where the gradient of <InlineMath math="\mathcal{L}" /> with respect to
                         both <InlineMath math="x" /> and <InlineMath math="\lambda" /> is zero.
                    </p>

                    <p className="subsubsection-paragraph">
                        In NLP, Lagrangians are particularly useful in scenarios where optimization problems involve constraints. For example:
                        <ul>
                            <li><b>Resource Allocation:</b> In problems like allocating computational resources for different tasks in a pipeline, constraints might include available memory or time. 
                            Lagrangians can be used to optimize performance while adhering to these constraints.</li>
                            <li><b>Model Training with Constraints:</b> Some NLP models may have constraints related to fairness, diversity, or specific performance metrics. Lagrangians enable these 
                            constraints to be incorporated directly into the training process. Perhaps Google's Gemini used them.</li>
                            <li><b>Optimization in Machine Translation:</b> In machine translation, certain linguistic or syntactic constraints can be formulated and integrated into the translation 
                            model using Lagrangians, ensuring that the output adheres to these constraints.</li>
                        </ul>
                        The use of Lagrangians in such contexts facilitates a more nuanced and controlled approach to optimization, allowing for the balancing of objectives and constraints, which is 
                        often a key consideration in complex NLP applications.
                    </p>

                    <p className="subsubsection-paragraph">
                        It's important to note that while Lagrangians provide a powerful framework for handling constraints, solving Lagrangian-based optimization problems, especially in 
                        high-dimensional spaces typical of NLP, can be computationally challenging and often requires sophisticated numerical methods. Instead, their usage can often be more 
                        in conjunction with NLP tasks rather than a part of it such as optimizing a group of similar users when you have some constraints on other attributes of those users 
                        outside the textual information.
                    </p>

                    
                    <h4>Convexity in Optimization</h4>

                    <p className="subsubsection-paragraph">
                        Convexity plays a critical role in optimization, especially in determining the ease and reliability of finding the global minimum of a function. A function is convex 
                        if the line segment between any two points on the graph of the function lies above or on the graph.
                    </p>

                    
                    <p className="subsubsection-paragraph">
                        Formally, a function <InlineMath math="f: \mathbb{R}^n \to \mathbb{R}" /> is convex if for any two points <InlineMath math="x" /> and <InlineMath math="y" /> in its 
                        domain, and for any <InlineMath math="\theta \in [0, 1]" />, the following inequality holds:
                        <BlockMath math="f(\theta x + (1 - \theta)y) \leq \theta f(x) + (1 - \theta)f(y)" />
                        A simple example is the quadratic function <InlineMath math="f(x) = x^2" />, which is convex because its second derivative is positive, indicating that the slope of the 
                        function increases as <InlineMath math="x" /> increases.
                    </p>

                    
                    <p className="subsubsection-paragraph">
                        In NLP, convex optimization is particularly important in models where global minima are sought. Many traditional NLP models, like logistic regression for text 
                        classification or linear support vector machines, involve convex loss functions. This convexity ensures that the optimization algorithms, like gradient descent, 
                        reliably converge to a global minimum, leading to stable and predictable model training.
                    </p>

                    {/* <p className="subsubsection-paragraph">
                        However, in modern NLP, with the advent of deep learning, the loss landscapes of models like neural networks are often non-convex. This non-convexity implies multiple 
                        local minima, making optimization more challenging. Nevertheless, understanding convexity remains crucial as it provides foundational knowledge for developing and analyzing 
                        optimization algorithms, even in non-convex contexts. It also contributes to the theoretical understanding of why certain approaches, like regularization techniques or 
                        specific network architectures, might help in navigating the complex optimization landscapes of deep learning models in NLP.
                    </p>

                    <p className="subsubsection-paragraph">
                        Therefore, while direct applications of convex optimization might be limited in some advanced NLP tasks, the principles underlying convex analysis continue to inform and 
                        guide the development of robust optimization strategies in the field -- they are important especially when you care about ensembling your methods. 
                    </p> */}


                </section>


                <section id="other" className="code-cleaned">
                    <h2>Some Issues of Note</h2>
                    <p className="subsubsection-paragraph"></p>

                    <h4>Vanishing & Exploding Gradients</h4>

                    <p className="subsubsection-paragraph">
                        Exploding and vanishing gradients are significant challenges encountered in training deep neural networks, particularly in NLP. These phenomena occur during backpropagation, 
                        impacting the efficiency and effectiveness of training deep learning models.
                    </p>

                    <p className="subsubsection-paragraph">
                        <b>Exploding Gradients:</b> This occurs when the gradients of the loss function become too large, causing updates to the model's parameters to be excessively large and 
                        unstable. Mathematically, if the weights of the network are large or the gradient of the activation function is large, the gradients can grow exponentially with each layer 
                        in a deep network:
                        <BlockMath math="\frac{\partial \mathcal{L}}{\partial \theta} = \prod_{i=1}^{n} \left| \frac{\partial h_i}{\partial h_{i-1}} \right| \frac{\partial \mathcal{L}}{\partial h_n}" />
                        Here, <InlineMath math="\mathcal{L}" /> is the loss function, <InlineMath math="\theta" /> represents the parameters, and <InlineMath math="h_i" /> is the activation at 
                        layer <InlineMath math="i" />.
                    </p>

                    <p className="subsubsection-paragraph">
                        <b>Vanishing Gradients:</b> This occurs when the gradients of the loss function become too small, leading to insignificant updates to the model's parameters. 
                        In deep networks, especially with certain activation functions like the sigmoid, gradients can diminish exponentially as they propagate back through each layer:
                        <BlockMath math="\frac{\partial \mathcal{L}}{\partial \theta} = \prod_{i=1}^{n} \left| \frac{\partial h_i}{\partial h_{i-1}} \right| \frac{\partial \mathcal{L}}{\partial h_n}" />
                        If <InlineMath math="\left| \frac{\partial h_i}{\partial h_{i-1}} \right|" /> is small, the overall gradient can become extremely small.
                    </p>

                    <p className="subsubsection-paragraph">
                        In NLP, these issues are particularly pronounced due to the use of recurrent neural networks and deep learning architectures, which often have many layers. 
                        Exploding gradients can cause model training to diverge, while vanishing gradients can result in training stagnation, where the model fails to learn or improve over time.
                    </p>

                    <p className="subsubsection-paragraph">
                        To mitigate these issues, several techniques are employed:
                        <ul>
                            <li><b>Gradient Clipping:</b> This involves capping the gradients during backpropagation to prevent them from exploding.</li>
                            <li><b>Use of LSTM/GRU:</b> Long Short-Term Memory units or Gated Recurrent Units in RNNs help in mitigating the vanishing gradient problem by 
                            incorporating mechanisms that allow gradients to flow more effectively -- more on this later.</li>
                            <li><b>Weight Initialization:</b> Proper initialization of network weights can help in preventing vanishing and exploding gradients.</li>
                            <li><b>Batch Normalization:</b> This technique normalizes the input layer by adjusting and scaling the activations, which can help in stabilizing the learning process 
                            and combating the vanishing gradient problem.</li>
                        </ul>
                        Addressing these challenges is crucial for training effective NLP models, as it directly impacts the model's ability to learn from data and perform tasks such as language translation,
                         text generation, and sentiment analysis.
                    </p>

                    <h4>Gradient Clipping</h4>
                    <p className="subsubsection-paragraph">
                        Gradient clipping is a technique used to address the issue of exploding gradients in training deep neural networks.
                    </p>

                    <p className="subsubsection-paragraph">
                        The concept of gradient clipping involves adjusting the gradients of a model's parameters during backpropagation if they exceed a certain threshold. The general approach 
                        can be described as:
                        <BlockMath math="\text{if } \|\mathbf{g}\| > v, \text{ then } \mathbf{g} = \frac{v \cdot \mathbf{g}}{\|\mathbf{g}\|}" />
                        Here, <InlineMath math="\mathbf{g}" /> represents the gradient vector computed during backpropagation, <InlineMath math="v" /> is the threshold value, 
                        and <InlineMath math="\|\mathbf{g}\|" /> is the norm of the gradient vector. If the norm exceeds the threshold, the gradient is scaled down to keep its norm at 
                        or below <InlineMath math="v" />.
                    </p>

                    <p className="subsubsection-paragraph">
                        In NLP, gradient clipping is an essential technique for training recurrent neural networks and LSTMs. These architectures are particularly susceptible to
                         exploding gradients due to the compounding of gradients through time, which
                         can lead to unstable training behavior and divergence.
                    </p>

                    <p className="subsubsection-paragraph">
                        By implementing gradient clipping, the gradients are kept within manageable bounds, ensuring that the updates to the model parameters during training are not excessively 
                        large. This not only stabilizes the training process but also aids in achieving faster convergence and better overall performance of the model.
                    </p>

                    <p className="subsubsection-paragraph">
                        Additionally, gradient clipping can be beneficial in any deep learning architecture where deep stacks of layers are trained, as it helps maintain control over the learning
                         trajectory.
                    </p>

                    <h4>Normalization</h4>
                    <p className="subsubsection-paragraph">
                        This probably should be in the linear algebra section but I am too lazy to move it now; normalization is a crucial preprocessing step in machine learning. It involves
                         transforming data to a common scale without distorting differences in the ranges 
                        of values, which helps in speeding up the training process and achieving better performance.
                    </p>

                    <p className="subsubsection-paragraph">
                        <b>1. Min-Max Normalization:</b> This technique rescales the feature to a fixed range, usually 0 to 1. The formula is given by:
                        <BlockMath math="x_{\text{norm}} = \frac{x - \text{min}(x)}{\text{max}(x) - \text{min}(x)}" />
                        where <InlineMath math="x" /> is an original value, <InlineMath math="\text{min}(x)" /> and <InlineMath math="\text{max}(x)" /> are the minimum and maximum values 
                        in the feature, respectively.
                    </p>

                    <p className="subsubsection-paragraph">
                        <b>2. Standardization (Z-score normalization):</b> Here, the features are rescaled so that they have the properties of a standard normal distribution with a mean of 
                        zero and a standard deviation of one:
                        <BlockMath math="x_{\text{norm}} = \frac{x - \mu}{\sigma}" />
                        where <InlineMath math="\mu" /> is the mean and <InlineMath math="\sigma" /> is the standard deviation of the feature.
                    </p>

                    <p className="subsubsection-paragraph">
                        <b>3. L2 Normalization:</b> This technique normalizes each sample to have a unit norm. Each component of a feature vector is divided by the Euclidean length of the vector:
                        <BlockMath math="x_{\text{norm}} = \frac{x}{\|\mathbf{x}\|_2}" />
                        where <InlineMath math="\|\mathbf{x}\|_2" /> is the L2 norm of the vector <InlineMath math="\mathbf{x}" />.
                    </p>

                    <p className="subsubsection-paragraph">
                        In NLP, normalization is especially important due to the variability in language data. Normalizing text data ensures that specific features or words do not dominate others 
                        due to their scale, which is crucial for models like neural networks that are sensitive to the scale of input data.
                    </p>

                    <p className="subsubsection-paragraph">
                        For example, in text classification tasks, word frequencies or TF-IDF values are normalized to prevent 
                        features with larger scales from influencing the model more than features with smaller scales. This leads to more balanced training and better model performance.
                    </p>

                    <p className="subsubsection-paragraph">
                        Additionally, normalization is vital in word embedding techniques, where words are represented as vectors in high-dimensional space. Normalizing these vectors can improve 
                        the performance of cosine similarity calculations, which are often used to measure semantic similarity between words. In deep learning models for NLP, techniques like batch normalization are employed during training to normalize the inputs of each layer. This stabilizes 
                        the learning process and has been shown to significantly accelerate training in deep neural networks.
                    </p>


                </section>
                
                
                <div className="subsubsection-navigation">
                    <Link to="/foundations/linalg">← Linear Algebra</Link>
                    <Link to="/foundations/probstat">Probability & Statistics →</Link>
                </div>
            </main>
            
            <Footer />
        </div>
    );
}

export default Calculus;
