import React from 'react';
import '../../styles/section.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

function LLMs() {
    return (
        <div className="section-container">
            <Header />
            <main className="section-content">
                <p className="section-intro-paragraph">Learn about ChatGPT</p>
                <div className="subsection-links">
                    <a href="/llms/basics">Basics</a>
                    <a href="/llms/training">Training</a>
                    <a href="/llms/distillation">Distillation</a>
                    <a href="/llms/applications">Applications</a>
                    <a href="/llms/ethics">Ethics</a>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default LLMs;