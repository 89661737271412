import React from 'react';
import '../../styles/subsection.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import 'katex/dist/katex.min.css';

function Basics() {
    return (
        <div className="subsubsection-container">
            <Header />
            <div class="side-nav-container">
                <aside className="subsubsection-side-nav">
                    <a href="#intro">LLM Introduction</a>
                    <a href="#hist">LLM History</a>
                    <a href="#future">LLM Future</a>
                </aside>
            </div>
            
            <main className="subsubsection-content">
                <div className="titles"><h1>LLM Basics</h1></div>

                <section id="intro">
                <h2>Introduction to LLMs</h2>
                <p className="subsubsection-paragraph">
                    Large Language Models (LLMs) are the current pinnacle of NLP and all its advancements. LLMs leverage architectures like Transformers to process and predict
                      language sequences. Their size and the vast amount of training data enable them to capture a wide range of linguistic 
                     nuances, idiomatic expressions, and syntactic structures bringing together many of the concepts we have already discussed. 
                     LLMs are not just limited to language understanding and generation tasks; they also excel in language translation,
                      question-answering, summarization, and more complex tasks like logical reasoning and creative writing. Their flexibility and capacity to handle various language-related 
                      tasks make them integral to applications ranging from chatbots and virtual assistants to content creation and language analysis tools. They will only become 
                      more and more prominent as better, more "intelligent" models are rolled out. 
                </p>
            </section>

            <section id="hist">
                <h2>LLM History</h2>
                <p className="subsubsection-paragraph">
                    The development of Large Language Models began with foundational machine learning concepts and took a significant leap forward with the introduction of neural networks.
                    The goal was to build a way to predict entire responses given a question and other contextual information and, while there was progress made, the older sequence models like RNN variants
                    had bottlenecks around efficiency that limited practicality. 
                </p>
                <p className="subsubsection-paragraph">
                    The introduction of the Transformer architecture marked a pivotal moment, enhancing the feaasibility and effectiveness of LLMs. This innovation led to the development of 
                    advanced models like GPT and BERT, which significantly improved language understanding and generation capabilities. These models, trained on extensive datasets, were able to produce
                     text with a level of coherence and relevance that was previously unattainable.
                </p>
                <p className="subsubsection-paragraph">
                    Subsequent iterations, such as GPT-2 and GPT-3, built upon the success of the original GPT model by increasing the scale and complexity, which further improved language generation
                     quality. Enhancements to BERT, including variants like RoBERTa and ALBERT, focused on refining the model's ability to understand and interpret text, pushing the boundaries of NLP 
                     performance across a range of tasks.
                </p>
            </section>



            <section id="future">
                <h2>LLM Future</h2>
                <p className="subsubsection-paragraph">
                    I wish I had a better write up for this section but advancements are being made at a meteoric pace so it's difficult to say something here without it being 
                    near immediately outdated. OpenAI recently introduced Sora though, which was pretty cool. 
                </p>
            </section>



                
                
                <div className="subsubsection-navigation">
                    <Link to="/llms">← Large Language Models</Link>
                    <Link to="/llms/training">LLM Training →</Link>
                </div>
            </main>
            
            <Footer />
        </div>
    );
}

export default Basics;
