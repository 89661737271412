import React from 'react';
import '../../styles/section.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

function Frontier() {
    return (
        <div className="section-container">
            <Header />
            <main className="section-content">
                <p className="section-intro-paragraph">Read about recent advances</p>
                <div className="subsection-links">
                    <a href="/frontier/prophetnet">ProphetNET</a>
                    <a href="/frontier/reformers">Reformers</a>
                    <a href="/frontier/electra">ELECTRA</a>
                    {/* <a href="/frontier/unifiedqa">UnifiedQA</a> */}
                    <a href="/frontier/ctrl">CTRL: Conditional Transformers</a>
                    <a href="/frontier/pegasus">Pegasus</a>
                    <a href="/frontier/ernie">ERNIE</a>
                    <a href="/frontier/future">Future</a>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default Frontier;