// React
import React from "react";
import {
  BrowserRouter,
  Route,
  Routes
} from "react-router-dom";

// Page Imports
import Main from "./pages/Main";

import Begin from './pages/Begin';
import WhatIsIt from './pages/Begin/whatisit';
import History from './pages/Begin/history';
import Structure from './pages/Begin/structure';

import Foundations from "./pages/Foundations";
import Python from './pages/Foundations/python';
import LinearAlgebra from './pages/Foundations/linalg';
import Calculus from './pages/Foundations/calc';
import Probability from './pages/Foundations/probstat';
import NeuralNetworks from './pages/Foundations/nn';

import NLPBasics from "./pages/NLPBasics";
import NLPData from "./pages/NLPBasics/data"
import Linguistics from "./pages/NLPBasics/ling"
import Semantics from "./pages/NLPBasics/semantic"
import Evaluation from "./pages/NLPBasics/eval"

import ML from "./pages/ML";
import AdvancedML from "./pages/ML/adv"
import ClassicML from "./pages/ML/classic"
import CNN from "./pages/ML/cnn"
import RNN from "./pages/ML/rnn"
import OGML from "./pages/ML/mlalgo"
import Seq2Seq from "./pages/ML/seq2seq"
import Transfer from "./pages/ML/transfer"

import LLMs from "./pages/LLMs";
import Applications from "./pages/LLMs/applications"
import Basics from "./pages/LLMs/basics"
import Distillation from "./pages/LLMs/distillation"
import Ethics from "./pages/LLMs/ethics"
import Training from "./pages/LLMs/training"

import ExistingModels from "./pages/ExistingModels";
import AdvancedExisting from "./pages/ExistingModels/advancedexisting";
import Bert from "./pages/ExistingModels/bert";
import Generative from "./pages/ExistingModels/generativepretrained";
import HuggingFace from "./pages/ExistingModels/huggingface";
import ZeroShot from "./pages/ExistingModels/zeroshot";

import Frontier from "./pages/Frontier";
import CTRL from "./pages/Frontier/ctrl"
import Electra from "./pages/Frontier/electra";
import Ernie from "./pages/Frontier/ernie";
import Future from "./pages/Frontier/future";
import Prophet from "./pages/Frontier/prophetnet";
import Pegasus from "./pages/Frontier/pegasus";
import Reformers from "./pages/Frontier/reformers";
import UnifiedQA from "./pages/Frontier/unifiedqa";

import About from "./pages/About"
import AstroML from "./pages/About/astroml"

// Component Imports
import ScrollToTop from "./components/ScrollToTop"

// Style Imports
import './styles/main.css';

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop /> 
      <div className="App">
        <Routes>

          <Route path="/" element={<Main />} />
          <Route path="/about" element={<About />} />
          <Route path="/about/astroml" element={<AstroML />} />

          <Route path="/begin" element={<Begin />} />
          <Route path="/begin/whatisit" element={<WhatIsIt />} />
          <Route path="/begin/history" element={<History />} />
          <Route path="/begin/structure" element={<Structure />} />

          <Route path="/foundations" element={<Foundations />} />
          <Route path="/foundations/python" element={<Python />} />
          <Route path="/foundations/linalg" element={<LinearAlgebra />} />
          <Route path="/foundations/calc" element={<Calculus />} />
          <Route path="/foundations/probstat" element={<Probability />} />
          <Route path="/foundations/nn" element={<NeuralNetworks />} />

          <Route path="/nlpbasics" element={<NLPBasics />} />
          <Route path="/nlpbasics/data" element={<NLPData />} />
          <Route path="/nlpbasics/eval" element={<Evaluation />} />
          <Route path="/nlpbasics/ling" element={<Linguistics />} />
          <Route path="/nlpbasics/semantic" element={<Semantics />} />

          <Route path="/ml" element={<ML />} />
          <Route path ="ml/mlalgo" element={<OGML />} />
          <Route path="/ml/classic" element={<ClassicML />} />
          <Route path="/ml/rnn" element={<RNN />} />
          <Route path="/ml/cnn" element={<CNN />} />
          <Route path="/ml/seq2seq" element={<Seq2Seq />} />
          <Route path="/ml/adv" element={<AdvancedML />} />
          <Route path="/ml/transfer" element={<Transfer />} />

          <Route path="/llms" element={<LLMs />} />
          <Route path="/llms/basics" element={<Basics />} />
          <Route path="/llms/training" element={<Training />} />
          <Route path="/llms/distillation" element={<Distillation />} />
          <Route path="/llms/applications" element={<Applications />} />
          <Route path="/llms/ethics" element={<Ethics />} />

          <Route path="/existingmodels" element={<ExistingModels />} />
          <Route path="/existingmodels/bert" element={<Bert />} />
          <Route path="/existingmodels/generativepretrained" element={<Generative />} />
          <Route path="/existingmodels/advancedexisting" element={<AdvancedExisting />} />
          <Route path="/existingmodels/zeroshot" element={<ZeroShot />} />
          <Route path="/existingmodels/huggingface" element={<HuggingFace />} />

          <Route path="/frontier" element={<Frontier />} />
          <Route path="/frontier/prophetnet" element={<Prophet />} />
          <Route path="/frontier/reformers" element={<Reformers />} />
          <Route path="/frontier/electra" element={<Electra />} />
          <Route path="/frontier/unifiedqa" element={<UnifiedQA />} />
          <Route path="/frontier/ctrl" element={<CTRL />} />
          <Route path="/frontier/pegasus" element={<Pegasus />} />
          <Route path="/frontier/ernie" element={<Ernie />} />
          <Route path="/frontier/future" element={<Future />} />

        </Routes>
      </div>
    </BrowserRouter>
  );
}

export default App;
