import React from 'react';
import '../../styles/subsection.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import 'katex/dist/katex.min.css';
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function Ernie() {
    return (
        <div className="subsubsection-container">
            <Header />
            <div class="side-nav-container">
                <aside className="subsubsection-side-nav">
                    <a href="#1">Concept</a>
                    <a href="#2">Code</a>
                </aside>
            </div>
            
            <main className="subsubsection-content">
                <div className="titles"><h1>ERNIE</h1></div>

                <section id="1" className="code-cleaned">
                    <h2>Concept</h2>
                        <p className="subsubsection-paragraph">
                        
                            ERNIE, which stands for "Enhanced Representation through kNowledge Integration," represents a significant evolution in the realm of natural language processing models. 
                            Developed by Baidu, ERNIE is designed to enhance the understanding of language by integrating external knowledge, such as world knowledge or linguistic structures, into its 
                            pre-training process. Unlike traditional models that primarily learn from the text itself, ERNIE aims to grasp the deeper semantic connections between words and phrases by 
                            leveraging structured knowledge from various sources. </p>

                            <p className="subsubsection-paragraph">
                            The main focus of ERNIE was in its ability to capture and utilize rich semantic information, which is often implicit in the text. For example, it can understand that
                             "Paris" is not just a word but a capital city with historical and cultural significance, thereby enriching its language representations with this broader context. This is 
                             achieved through novel pre-training tasks that encourage the model to learn relationships between entities, concepts, and their attributes, making ERNIE particularly adept 
                             at tasks requiring a deep understanding of context and semantics, such as question answering, named entity recognition, and sentiment analysis.
                            </p>

                            <p className="subsubsection-paragraph">
                            You can read more about this approach in: "ERNIE: Enhanced Language Representation with Informative Entities," authored by Zhengyan Zhang, Xu Han, Zhiyuan Liu, Xin Jiang, Maosong Sun, and Qun Liu
                            </p>

                            </section>

                            <section id="2" className="code-cleaned">
            <h2>In Code</h2>
                            <p className="subsubsection-paragraph">
                            
<SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
{`from transformers import AutoTokenizer, AutoModelForSequenceClassification
import torch

# Load the pre-trained ERNIE model and tokenizer
model_name = 'nghuyong/ernie-2.0-en'
tokenizer = AutoTokenizer.from_pretrained(model_name)
model = AutoModelForSequenceClassification.from_pretrained(model_name)

# Example sentence
sentence = "The quick brown fox jumps over the lazy dog."

# Encode the sentence
inputs = tokenizer(sentence, return_tensors="pt")

# Predict
with torch.no_grad():
    logits = model(**inputs).logits

# Interpret the model output (for example, by applying a softmax function to obtain probabilities for each class)
probabilities = torch.softmax(logits, dim=1)

print("Probabilities:", probabilities)
`}
</SyntaxHighlighter>
</p>
                </section>

                <div className="subsubsection-navigation">
                    <Link to="/frontier/pegasus">← Pegasus</Link>
                    <Link to="/frontier/future">Future →</Link>
                </div>
            </main>
            
            <Footer />
        </div>
    );
}

export default Ernie;
