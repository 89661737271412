import React from 'react';
import '../../styles/subsection.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import 'katex/dist/katex.min.css';
import { InlineMath } from 'react-katex';
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function Transfer() {
    return (
        <div className="subsubsection-container">
            <Header />
            <div class="side-nav-container">
                <aside className="subsubsection-side-nav">
                    <a href="#transfer">Concept</a>
                    <a href="#pop">Models</a>
                </aside>
            </div>
            
            <main className="subsubsection-content">
                <div className="titles"><h1>Transfer Learning</h1></div>

                <section id="transfer">
                <h2>Conceptual Foundation</h2>
                <p className="subsubsection-paragraph">
                    Transfer learning in NLP involves leveraging knowledge gained from one problem to solve related but different problems. This approach has gained prominence 
                    due to its ability to improve learning efficiency and performance, especially when labeled data is scarce.
                </p>

                <p className="subsubsection-paragraph">
                    The core idea of transfer learning is to transfer the knowledge from a source task, which has abundant data, to a target task with limited data. This is based on the
                     premise that certain features or representations learned from the source task are generalizable and can be beneficial for the target task. For instance, a language 
                     model trained on a large corpus can capture syntactic and semantic patterns of the language, which are useful across various NLP tasks. It's basically like making 
                     some model that is generally smart first and then using that model as the starting point for a more specific task. 
                </p>

                <p className="subsubsection-paragraph">
                    There are primarily two methods in transfer learning: feature-based and fine-tuning. Feature-based transfer learning involves using features learned by a pre-trained 
                    model as inputs for a new model. Fine-tuning, on the other hand, adjusts the weights of an already trained model on a new task, allowing for more flexibility and adaptation.
                     This process can be mathematically described where a pre-trained model with parameters <InlineMath math="\theta" /> is fine-tuned on a new dataset to obtain updated 
                     parameters <InlineMath math="\theta'" />, by minimizing a loss function <InlineMath math="L" /> specific to the target task.
                </p>
            </section>

            <section id="pop" className="code-cleaned">
                <h2>Popular Models for Transfer Learning</h2>

                <h4>Some Models</h4>
                <p className="subsubsection-paragraph">
                    Some notable models include BERT (Bidirectional Encoder Representations from Transformers), GPT (Generative Pre-trained Transformer), and ELMo (Embeddings from Language Models).
                     BERT, for instance, learns language representations by pre-training on a large text corpus using tasks like masked language modeling and next sentence prediction. GPT follows a
                      similar approach but focuses on using transformer-based architectures for generative tasks. ELMo utilizes bidirectional LSTM layers to create deep contextualized word embeddings.
                      We'll have more on this later as well. 
                </p>

                <h4>Tuning BERT</h4>
                <p className="subsubsection-paragraph">
                    Fine-tuning BERT involves adjusting its pre-trained layers to better suit a specific NLP task. This process typically includes adding task-specific layers on top of BERT, 
                    followed by training the entire model end-to-end on task-specific data. The fine-tuning adjusts BERT’s parameters <InlineMath math="\theta" /> to new
                     parameters <InlineMath math="\theta'" /> that better capture the nuances of the target task. This fine-tuning process is often guided by a task-specific loss function,
                      allowing BERT to adapt its rich pre-trained language representations to the specific requirements of the new task.

                      <SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
    {`import tensorflow as tf
from transformers import BertTokenizer, TFBertModel, BertConfig
from tensorflow.keras.layers import Input, Dense, Dropout
from tensorflow.keras.models import Model
from tensorflow.keras.optimizers import Adam

# Load pre-trained BERT model and tokenizer
bert_model_name = 'bert-base-uncased'
tokenizer = BertTokenizer.from_pretrained(bert_model_name)
bert_model = TFBertModel.from_pretrained(bert_model_name)

# Model architecture
input_ids = Input(shape=(512,), dtype='int32', name='input_ids')
attention_mask = Input(shape=(512,), dtype='int32', name='attention_mask')

# We only use the output of the [CLS] token for classification tasks
bert_output = bert_model(input_ids, attention_mask=attention_mask)[1]
dropout = Dropout(0.1)(bert_output)
output = Dense(1, activation='sigmoid')(dropout)

# Define and compile the model
model = Model(inputs=[input_ids, attention_mask], outputs=output)
model.compile(optimizer=Adam(learning_rate=2e-5), loss='binary_crossentropy', metrics=['accuracy'])

# Preprocess and encode the data
def encode_data(tokenizer, texts, max_len=512):
    input_ids = []
    attention_masks = []

    for text in texts:
        encoded_text = tokenizer.encode_plus(
            text, 
            add_special_tokens=True, 
            max_length=max_len, 
            pad_to_max_length=True, 
            return_attention_mask=True
        )
        input_ids.append(encoded_text['input_ids'])
        attention_masks.append(encoded_text['attention_mask'])

    return np.array(input_ids), np.array(attention_masks)

# Example data (list of texts and labels)
texts = ['This is great!', 'This is terrible...']
labels = [1, 0]  # 1 for positive, 0 for negative sentiment

# Encode the data
input_ids, attention_masks = encode_data(tokenizer, texts)
labels = np.array(labels)

# Train the model
model.fit([input_ids, attention_masks], labels, epochs=3, batch_size=8)

# Model evaluation and inference can be done using the standard Keras methods`}
            </SyntaxHighlighter>

                </p>
            </section>

                
                
                <div className="subsubsection-navigation">
                    <Link to="/ml/adv">← Other Architectures</Link>
                    <Link to="/llms">Large Language Models →</Link>
                </div>
            </main>
            
            <Footer />
        </div>
    );
}

export default Transfer;
