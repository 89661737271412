import React from 'react';
import '../../styles/subsection.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';

function Future() {
    return (
        <div className="subsubsection-container">
            <Header />
            <div class="side-nav-container">
                <aside className="subsubsection-side-nav">
                    <a href="#1">Thank You</a>
                </aside>
            </div>
            
            <main className="subsubsection-content">
                <div className="titles"><h1>Future</h1></div>

                <section id="1">
                    <h2>Thank You</h2>
                    <p className="subsubsection-paragraph">I don't really have much to say about the future of NLP. 
                    This was meant largely as a personal project so I didn't always keep the reader in mind but I figured it might
                    be better to be out there as a reference anyway; I hope you enjoyed this walkthrough and got something out of it!</p>

                </section>

                
                
                <div className="subsubsection-navigation">
                    <Link to="/frontier/ernie">← ERNIE</Link>
                    <Link to="/">Home</Link>
                </div>
            </main>
            
            <Footer />
        </div>
    );
}

export default Future;
