import React from 'react';
import '../../styles/subsection.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import 'katex/dist/katex.min.css';
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function Pegasus() {
    return (
        <div className="subsubsection-container">
            <Header />
            <div class="side-nav-container">
                <aside className="subsubsection-side-nav">
                    <a href="#1">Concept</a>
                    <a href="#2">Code</a>
                </aside>
            </div>
            
            <main className="subsubsection-content">
                <div className="titles"><h1>Pegasus</h1></div>

                <section id="1" className="code-cleaned">
                    <h2>Concept</h2>
                        <p className="subsubsection-paragraph">
                        PEGASUS, which stands for "Pre-training with Extracted Gap-sentences for Abstractive SUmmarization Sequence-to-sequence models," is a significant advancement in text 
                        summarization. Developed by researchers at Google, PEGASUS addresses the challenge of creating concise, relevant summaries from longer texts. Unlike traditional models that often
                         rely on extracting key sentences or phrases verbatim from the text, PEGASUS adopts an abstractive approach, enabling it to paraphrase and condense information in a way that mimics
                          human summarization skills more closely. </p>

                            <p className="subsubsection-paragraph">
                            The innovative aspect of PEGASUS lies in its pre-training technique, where it learns to identify and then fill in "gaps" in the text. During training, the model is presented with 
                            texts from which certain sentences (the "gaps") have been removed. The task for PEGASUS is to predict these missing sentences based on the surrounding context. This gap-sentence 
                            pre-training encourages the model to understand the text deeply and identify its most salient points, which is crucial for generating informative and coherent summaries.
                            </p>

                            <p className="subsubsection-paragraph">
                            You can read more about this approach in: "PEGASUS: Pre-training with Extracted Gap-sentences for Abstractive Summarization," authored by Jingqing Zhang, Yao Zhao, Mohammad 
                            Saleh, and Peter J. Liu
                            </p>

                            </section>

                            <section id="2" className="code-cleaned">
                            <h2>In Code</h2>
                            <p className="subsubsection-paragraph">

                            This may run slow.
                            
<SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
{`from transformers import PegasusTokenizer, PegasusForConditionalGeneration

# Load the pre-trained PEGASUS model and tokenizer
model_name = 'google/pegasus-xsum'
tokenizer = PegasusTokenizer.from_pretrained(model_name)
model = PegasusForConditionalGeneration.from_pretrained(model_name)

# Define your input text that you want to summarize
input_text = """The COVID-19 pandemic has led to a dramatic loss of human life worldwide and presents an unprecedented challenge to public health, food systems, and the world of work. The economic and social disruption caused by the pandemic is devastating: tens of millions of people are at risk of falling into extreme poverty, while the number of undernourished people, currently estimated at nearly 690 million, could increase by up to 132 million by the end of the year."""

# Encode the input text
input_tokens = tokenizer.encode(input_text, return_tensors="pt")

# Generate the summary
summary_ids = model.generate(input_tokens, max_length=45, num_beams=4, length_penalty=2.0, early_stopping=True)

# Decode the summary
summary = tokenizer.decode(summary_ids[0], skip_special_tokens=True)

print("Summary:", summary)

`}
</SyntaxHighlighter>
</p>
                        
                </section>

                <div className="subsubsection-navigation">
                    <Link to="/frontier/ctrl">← CTRL</Link>
                    <Link to="/frontier/ernie">ERNIE →</Link>
                </div>
            </main>
            
            <Footer />
        </div>
    );
}

export default Pegasus;
