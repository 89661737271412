import React from 'react';
import '../../styles/subsection.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import 'katex/dist/katex.min.css';
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function Generative() {
    return (
        <div className="subsubsection-container">
            <Header />
            <div class="side-nav-container">
                <aside className="subsubsection-side-nav">
                    <a href="#gpt">GPT</a>
                    <a href="#t5">T5</a>
                </aside>
            </div>
            
            <main className="subsubsection-content">
                <div className="titles"><h1>Generative Pre-trained Transformers</h1></div>

                <section id="gpt" className="code-cleaned">
                    <h2>GPT</h2>
                    <p className="subsubsection-paragraph">
                    We've discussed GPT quite a bit already and for a more thorough explanation of the underlying fundamentals and the pre-training process, you can take a look at the previous sections. 

                     Following pre-training, GPT undergoes fine-tuning on a smaller, task-specific dataset. This stage adapts the model to particular NLP tasks such as text classification, sentiment analysis, 
                     question-answering, and more. The fine-tuning adjusts GPT's parameters to optimize performance on the desired task, leveraging the rich linguistic understanding acquired during 
                     pre-training. What sets GPT apart is its generative capabilities. Unlike models that only classify or extract information, GPT can generate coherent and contextually relevant text,
                      making it highly versatile for a wide range of applications. From writing assistance, content creation, and language translation to more complex tasks like summarization and 
                      text-based gaming, GPT is flexible enough to adapt to most language tasks.
                    </p>

                    <p className="subsubsection-paragraph">
                    The release of successive versions of GPT, with GPT-4 being the most notable for its unprecedented scale, has further demonstrated the model's capabilities. GPT-3's 175 billion parameters
                     enable it to perform a wide array of NLP tasks with little to no task-specific data, a property known as few-shot learning (you'll read about this soon). This ability to understand and
                      generate human-like text 
                     based on a minimal number of examples has opened new horizons in AI, pushing the boundaries of what's possible with machine learning models in understanding and interacting with human
                      language. Here's an example of how you might ping GPT:
                      <SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
{`from transformers import GPT2Tokenizer, GPT2LMHeadModel

# Load pre-trained GPT-2 model and tokenizer
model_name = 'gpt2'  # You can also try other versions like 'gpt2-medium', 'gpt2-large', 'gpt2-xl'.
tokenizer = GPT2Tokenizer.from_pretrained(model_name)
model = GPT2LMHeadModel.from_pretrained(model_name)

# Encode input text and add end of sequence token
input_text = "Once upon a time, in a land far, far away,"
input_tokens = tokenizer.encode(input_text, return_tensors="pt")

# Generate text
output_tokens = model.generate(input_tokens, max_length=100, pad_token_id=tokenizer.eos_token_id)

# Decode the generated tokens to a readable string
generated_text = tokenizer.decode(output_tokens[0], skip_special_tokens=True)

print("Generated Text: ", generated_text)`}
</SyntaxHighlighter>
                    </p>

                    

                </section>

                <section id="t5" className="code-cleaned">
                    <h2>T5: Text-to-Text Transfer Transformer</h2>
                    <p className="subsubsection-paragraph">
                    T5, short for Text-To-Text Transfer Transformer, introduces a unified framework that reframes all NLP tasks as text-to-text problems, where both input and output are always strings of 
                    text. Developed by Google Research, T5's design philosophy simplifies the traditional NLP pipeline by treating every task, whether it's translation, question answering, or classification,
                     as a matter of converting one type of text into another. This approach allows for a highly versatile and extensible model capable of handling a diverse array of tasks with a single 
                     coherent model architecture. <br/> <br/>
                    
                    At the heart of T5's innovation is its comprehensive pre-training regimen. Unlike models that are pre-trained on a single type of task such as language modeling, T5 is pre-trained on a 
                    multi-task mixture that includes tasks like translation, text summarization, question answering, and more. This pre-training is performed on a colossal dataset known as the "Colossal 
                    Clean Crawled Corpus" (C4), which encompasses a wide variety of text from the internet, providing a rich linguistic foundation for the model.
                    <br/> <br/>

                    The core architecture of T5 is based on the original Transformer model but is adapted to support the text-to-text framework. During training, T5 employs a denoising autoencoder strategy 
                    similar to BERT's masked language model, but with a critical difference: it randomly masks out spans of text, which the model then attempts to generate, effectively turning it into a
                     fill-in-the-blank task with variable-length blanks. <br /> <br />

                     T5's unified approach extends to its fine-tuning process, where the model is adapted to specific tasks by training on task-specific datasets. However, each task is framed as a text-to-text 
                     problem. For instance, a sentiment analysis task is reframed as converting a piece of text into a sentiment label expressed as text (e.g., "positive" or "negative"). <br />
                     <SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
{`from transformers import T5Tokenizer, T5ForConditionalGeneration

# Load the pre-trained T5 model and tokenizer
model_name = 't5-small'  # 't5-small' is a smaller version of the model; other versions include 't5-base', 't5-large', 't5-3b', and 't5-11b'.
tokenizer = T5Tokenizer.from_pretrained(model_name)
model = T5ForConditionalGeneration.from_pretrained(model_name)

# Define the task and input text
task_prefix = "summarize: "  # T5 uses prefixes to indicate the task it should perform.
input_text = task_prefix + "The COVID-19 pandemic has led to a dramatic loss of human life worldwide and presents an unprecedented challenge to public health, food systems, and the world of work. The economic and social disruption caused by the pandemic is devastating: tens of millions of people are at risk of falling into extreme poverty, while the number of undernourished people, currently estimated at nearly 690 million, could increase by up to 132 million by the end of the year."
input_tokens = tokenizer.encode(input_text, return_tensors="pt")

# Generate summary
summary_ids = model.generate(input_tokens, max_length=100, min_length=30, length_penalty=2.0, num_beams=4, early_stopping=True)

# Decode the generated summary
summary = tokenizer.decode(summary_ids[0], skip_special_tokens=True)

print("Summary: ", summary)
`}
</SyntaxHighlighter>
                    </p>

                    

                </section>
                
                
                <div className="subsubsection-navigation">
                    <Link to="/existingmodels/bert">← BERT</Link>
                    <Link to="/existingmodels/advancedexisting">Multimodal →</Link>
                </div>
            </main>
            
            <Footer />
        </div>
    );
}

export default Generative;
