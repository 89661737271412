import React from 'react';
import '../../styles/subsection.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import 'katex/dist/katex.min.css';
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function Distillation() {
    return (
        <div className="subsubsection-container">
            <Header />
            <div class="side-nav-container">
                <aside className="subsubsection-side-nav">
                    <a href="#what">What is Distillation</a>
                    <a href="#benefits">How it's Done</a>
                    <a href="#comp">In Code</a>
                </aside>
            </div>
            
            <main className="subsubsection-content">
                <div className="titles"><h1>LLM Distillation</h1></div>

                <section id="what">
                    <h2>What is Model Distillation?</h2>
                    <p className="subsubsection-paragraph">
                    Model distillation, also known as knowledge distillation, is a technique used in deep learning to transfer knowledge from a large, complex model (often referred to as the "teacher" model)
                     to a smaller, more efficient model (the "student" model). The goal of model distillation is to enable the student model to achieve performance close to that of the teacher model 
                     while being more computationally efficient and requiring fewer resources to run. This process is particularly relevant and valuable in deploying large language models to 
                     environments with limited computational resources, such as mobile devices or embedded systems.
                    </p>

                    <p className='subsubsection-paragraph'>

                    Model distillation is based on the idea that a large, well-trained model encapsulates a vast amount of information about the task it has been trained on, not just in 
                    the final output but also in the intermediate representations and output distributions. The distillation process aims to capture this rich information and transfer it 
                    to the student model, effectively "teaching" the student to mimic the teacher's behavior. Key Components of distillation are: 

                    <ul>
                        <li><strong>Teacher Model:</strong> A large, pre-trained model that has been trained on a vast dataset and has achieved high performance on the target task. In the context of
                         LLMs, this could be a model like GPT-3 or BERT.</li>
                        <li><strong>Student Model:</strong> A smaller, more efficient model that is designed to learn from the teacher model. The student model's architecture doesn't need to be identical
                         to the teacher's but should be capable of capturing the essential knowledge.</li>
                        <li><strong>Distillation Loss:</strong> The loss function used to measure the difference between the teacher's output distribution and the student's output. A common choice is the 
                        Kullback–Leibler (KL) divergence, which measures how one probability distribution diverges from a second, expected probability distribution.</li>
                        <li><strong>Temperature Scaling:</strong> A technique used to soften the output distributions of the models, making them smoother and easier for the student to learn from.
                         A temperature parameter (T) is used to control the smoothness, with higher values producing softer distributions. "Softening" is an adjustment to the output probabilities from 
                         say the softmax function that reduces the distances between the highest and lowest probabilities across the classes. Basically, it reduces confidence but this will be helpful 
                         when the student model needs to compare itself to the teacher model.</li>
                    </ul>


                    </p>
                </section>

                <section id="benefits" className="code-cleaned">
                    <h2>How Distillation is Done</h2>
                    <p className="subsubsection-paragraph">
                        The general process is: 

                        <ol>
                            <li><strong>Preparation:</strong> Train the teacher model on the target task until it achieves high performance. The student model is then initialized, 
                            potentially with a different, lighter architecture.</li>
                            <li><strong>Temperature Scaling:</strong> Apply temperature scaling to the outputs of both the teacher and student models to soften their output distributions.</li>
                            <li><strong>Training the Student:</strong> Train the student model using a distillation loss that encourages it to mimic the softened output distribution of the teacher. 
                            The student model may also be trained using the traditional hard label loss, in which case the total loss would be a weighted sum of the distillation loss and the hard label loss.</li>
                            <li><strong>Fine-tuning:</strong> Optionally, the student model can be further fine-tuned on the target task using the original hard labels, enhancing its performance.</li>
                        </ol>

                        Model distillation can vary significantly in complexity and computational demand, depending on several factors such as the size of the teacher and student models, the complexity of the task,
                         the size of the dataset used for distillation, and the desired level of performance. For smaller models and less complex tasks, model distillation can often be performed on local
                          machines with decent hardware specifications, especially if you have a powerful GPU. Local distillation is more feasible when the student model is significantly smaller than the 
                          teacher model, and the dataset used for distillation is not excessively large. Techniques like batch processing, mixed precision training, and model pruning can help optimize the use of 
                          local machine resources, making distillation more manageable on such setups. For larger implementations, you can use cloud computing or distributed computing (or both). 

                    </p>
                </section>


                <section id="comp" className="code-cleaned">
                    <h2>In Code</h2>
                    <p className="subsubsection-paragraph">
                        Let's take a look at how we would achieve this in code (this is just an example, may not work lol):

                        <SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
            {`import torch
from torch.utils.data import DataLoader
from transformers import DistilBertTokenizer, DistilBertForSequenceClassification
from transformers import Trainer, TrainingArguments
from datasets import load_dataset
import numpy as np

# Load a smaller pre-trained model as the "teacher"
teacher_model = DistilBertForSequenceClassification.from_pretrained('distilbert-base-uncased')

# Load the tokenizer
tokenizer = DistilBertTokenizer.from_pretrained('distilbert-base-uncased')

# Load a subset of the SST-2 dataset for demonstration
dataset = load_dataset('glue', 'sst2', split='train[:10%]')

# Preprocess the data
def preprocess_function(examples):
    return tokenizer(examples['sentence'], padding=True, truncation=True)

tokenized_dataset = dataset.map(preprocess_function, batched=True)

class StudentModel(torch.nn.Module):
    def __init__(self, embedding_dim, hidden_size, num_classes, vocab_size):
        super(StudentModel, self).__init__()
        self.embedding = torch.nn.Embedding(vocab_size, embedding_dim)  # Embedding layer
        self.fc1 = torch.nn.Linear(embedding_dim, hidden_size)
        self.relu = torch.nn.ReLU()
        self.fc2 = torch.nn.Linear(hidden_size, num_classes)
    
    def forward(self, input_ids):
        # Convert input_ids to embeddings
        embeddings = self.embedding(input_ids)  # Shape: [batch_size, seq_length, embedding_dim]

        # Pool the embeddings along the sequence length dimension
        pooled_output = torch.mean(embeddings, dim=1)  # Shape: [batch_size, embedding_dim]

        hidden = self.fc1(pooled_output)
        relu = self.relu(hidden)
        output = self.fc2(relu)
        return output

# Initialize the student model with the correct dimensions
vocab_size = tokenizer.vocab_size  # Assuming you're using the DistilBert tokenizer
embedding_dim = teacher_model.config.dim  # DistilBert's embedding dimension
student_model = StudentModel(embedding_dim=embedding_dim, hidden_size=256, num_classes=2, vocab_size=vocab_size)

# Prepare for training
train_dataset = tokenized_dataset.remove_columns(['sentence', 'idx'])
train_dataset.set_format('torch', columns=['input_ids', 'attention_mask', 'label'])
train_loader = DataLoader(train_dataset, batch_size=16)

# Define loss and optimizer
loss_fn = torch.nn.CrossEntropyLoss()
optimizer = torch.optim.Adam(student_model.parameters(), lr=1e-4)

# Distillation training loop
num_epochs = 3
for epoch in range(num_epochs):
    student_model.train()
    for batch in train_loader:
        # Forward pass of the teacher model with input_ids
        with torch.no_grad():
            teacher_logits = teacher_model(input_ids=batch['input_ids']).logits
        
        # Forward pass of the student model without converting input_ids to float
        student_logits = student_model(batch['input_ids'])
        
        # Compute distillation loss
        loss = loss_fn(student_logits, teacher_logits.argmax(dim=1))
        
        # Backward pass and optimization
        optimizer.zero_grad()
        loss.backward()
        optimizer.step()
    
    print(f"Epoch {epoch + 1}, Loss: {loss.item()}")

# The student model is now distilled and can be used for inference or further training`}
                        </SyntaxHighlighter>
                    </p>
                </section>




                
                
                <div className="subsubsection-navigation">
                    <Link to="/llms/training">← LLM Training</Link>
                    <Link to="/llms/applications">LLM Applications →</Link>
                </div>
            </main>
            
            <Footer />
        </div>
    );
}

export default Distillation;
