import React from 'react';
import '../../styles/subsection.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import 'katex/dist/katex.min.css';
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function AdvancedExisting() {
    return (
        <div className="subsubsection-container">
            <Header />
            <div class="side-nav-container">
                <aside className="subsubsection-side-nav">
                    {/* <a href="#xl">XLNet</a> */}
                    <a href="#multi">Multimodal</a>
                </aside>
            </div>
            
            <main className="subsubsection-content">
                <div className="titles"><h1>Multimodal Models</h1></div>

                {/* <section id="xlnet" className="code-cleaned">
                    <h2>XLNet</h2>
                    <p className="subsubsection-paragraph">
                        XLNet, introduced by researchers from Google Brain and Carnegie Mellon University, represents a significant advancement in the landscape of natural language
                         processing (NLP) models. It's designed to overcome limitations of previous models like BERT by integrating the best aspects of autoregressive language modeling and 
                         autoencoding within a unified framework.
                    </p>


                        <p className="subsubsection-paragraph">
                        At its core, XLNet leverages the Transformer-XL architecture, known for its effectiveness in capturing long-range dependencies in text. The key innovation of XLNet lies in
                         its permutation-based language modeling objective, which allows the model to learn bidirectional contexts by maximizing the expected likelihood over all permutations of the 
                         input sequence.
                        </p>
                        <p className="subsubsection-paragraph">
                        Mathematically, for an input sequence of tokens <InlineMath math="x_1, x_2, ..., x_T" />, XLNet computes the probability of the sequence by factorizing the joint probability
                         over all possible permutations <InlineMath math="\pi" /> of the sequence indices:
                        </p>
                        <BlockMath math="\max_{\theta} \mathbb{E}_{\pi \sim S_T} \left[ \sum_{t=1}^{T} \log p_{\theta}(x_{\pi(t)} | x_{\pi(<t)}) \right]" />
                        <p className="subsubsection-paragraph">
                        Here, <InlineMath math="\theta" /> denotes the parameters of the model, <InlineMath math="S_T" /> is the set of all permutations of the sequence, 
                        and <InlineMath math="x_{\pi(<t)}" /> represents the tokens at positions less than <InlineMath math="t" /> in the permuted sequence.
                        </p>
    


                        <p className="subsubsection-paragraph">
                        XLNet introduces a novel two-stream self-attention mechanism, consisting of a content stream and a query stream, to effectively capture the positional information 
                        and the context of each token in the permuted sequences. This mechanism ensures that the model can use the correct context for prediction without seeing the target 
                        token itself, a critical component for permutation-based language modeling.
                        </p>
          


                        <p className="subsubsection-paragraph">
                        XLNet has demonstrated state-of-the-art performance across a wide range of NLP benchmarks, including tasks like text classification, question answering, and sentiment analysis.
                         Its ability to model complex patterns in language and understand context deeply makes it a powerful tool for various NLP applications.
                        </p>
          

                        <p className="subsubsection-paragraph">
                        XLNet is particularly beneficial for tasks requiring an understanding of the entire input context, such as question answering and document summarization. Its permutation-based 
                        approach and two-stream attention allow it to handle ambiguities and nuances in language more effectively than models trained on unidirectional or masked language objectives.
                        </p>

                        <SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
{`
from transformers import XLNetTokenizer, XLNetForSequenceClassification
import torch

# Load the pre-trained XLNet tokenizer and model
tokenizer = XLNetTokenizer.from_pretrained('xlnet-base-cased')
model = XLNetForSequenceClassification.from_pretrained('xlnet-base-cased', num_labels=2)

# Sample text for classification
text = "This new approach by XLNet shows promising results in NLP."

# Encode the text into input IDs and attention masks
inputs = tokenizer.encode_plus(text, add_special_tokens=True, return_tensors='pt')
input_ids = inputs['input_ids']
attention_mask = inputs['attention_mask']

# Predict the class label (e.g., 0 or 1) for the text
with torch.no_grad():
    outputs = model(input_ids=input_ids, attention_mask=attention_mask)
    logits = outputs.logits
    predicted_class_id = logits.argmax().item()

print(f"Predicted class ID: {predicted_class_id}")
`}
</SyntaxHighlighter>


                </section> */}

                <section id="multi" className="code-cleaned">
                    {/* <h2>Multimodal Models</h2> */}

                    <p className="subsubsection-paragraph">
                    Multimodal models in machine learning are systems designed to process and relate information from multiple different data types or "modes." These modes can include a variety of 
                    data forms such as text, images, audio, and other sensor data. The fundamental idea behind multimodal models is to capture the rich information available in the complex interplay
                     of different types of data, which often reflects more closely the way humans understand and interact with the world. For instance, when humans communicate, we do not rely solely on 
                     words (text); we also interpret visual cues (images, videos), vocal tones (audio), and sometimes even touch and smell. Similarly, multimodal machine learning models aim to integrate and
                      interpret this diverse data to make more accurate predictions or to understand content at a deeper level than could be achieved with a unimodal approach.
                    </p>


                    <p className="subsubsection-paragraph">

                        

                        Let's focus on CLIP, which is developed by OpenAI and stands as an excellent model that effectively bridges the gap between visual and linguistic data, enabling a wide array of
                         multimodal
                         tasks to be performed with a single model. Unlike traditional approaches that require separate models for image and text processing or rely heavily on task-specific
                          training data, CLIP learns visual concepts from natural language descriptions, allowing it to understand and perform tasks across both domains flexibly.
                    </p>


                        <p className="subsubsection-paragraph">
                        The core of CLIP consists of two primary components: an image encoder and a text encoder. The image encoder processes visual input, while the text encoder processes
                         textual input. Both encoders map their respective inputs to a shared embedding space where the similarity between the image and text embeddings can be directly compared. 
                         This design is inspired by the Transformer architecture.
                        </p>
                        

                        <p className="subsubsection-paragraph">
                        CLIP is trained using a contrastive learning objective, which encourages the model to align the embeddings of images with their corresponding textual descriptions while pushing
                         away the embeddings of non-matching pairs. This is achieved through a large-scale dataset of images and their associated text collected from the internet, enabling CLIP to 
                         learn a wide variety of visual concepts and their linguistic representations. CLIP's versatility allows it to excel in tasks such as zero-shot classification, where the model can correctly classify images into categories it has never seen during training, 
                        simply by understanding the textual description of the categories. Additionally, CLIP can be used for tasks like image retrieval, text-to-image generation, and more complex multimodal
                         tasks that require understanding the interplay between visual and textual data.
                        </p>

                        <p className="subsubsection-paragraph">
                        CLIP is particularly useful in scenarios where flexibility across visual and textual domains is needed, and where training data for specific tasks is limited or unavailable.
                         Its ability to generalize from natural language descriptions to visual concepts makes it a powerful tool for a broad range of applications, from enhancing search engines to
                          developing more intuitive human-computer interaction systems.
                          <SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
{`from PIL import Image
import requests
from transformers import CLIPProcessor, CLIPModel

# Load the pre-trained CLIP model and processor
model = CLIPModel.from_pretrained("openai/clip-vit-base-patch32")
processor = CLIPProcessor.from_pretrained("openai/clip-vit-base-patch32")

# Define the image and candidate class labels
image_url = "https://images.pexels.com/photos/1108099/pexels-photo-1108099.jpeg"
image = Image.open(requests.get(image_url, stream=True).raw)
candidate_labels = ["a cat", "a dog", "a horse"]

# Preprocess the image and text
inputs = processor(text=candidate_labels, images=image, return_tensors="pt", padding=True)

# Forward pass, get logits and softmax scores
outputs = model(**inputs)
logits_per_image = outputs.logits_per_image  # image logits are sized [batch_size, num_candidate_labels]
probs = logits_per_image.softmax(dim=1)

# Print the classification probabilities
for label, prob in zip(candidate_labels, probs[0]):
    print(f"Label: {label}, Probability: {prob:.4f}")
`}
</SyntaxHighlighter>
                        </p>

                        

                  
                </section>

                
                
                <div className="subsubsection-navigation">
                    <Link to="/existingmodels/generativepretrained">← Pre-Trained Generative</Link>
                    <Link to="/existingmodels/zeroshot">Zero Shot →</Link>
                </div>
            </main>
            
            <Footer />
        </div>
    );
}

export default AdvancedExisting;
