import React from 'react';
import '../../styles/subsection.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import 'katex/dist/katex.min.css';
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';


function Prophet() {
    return (
        <div className="subsubsection-container">
            <Header />
            <div class="side-nav-container">
                <aside className="subsubsection-side-nav">
                    <a href="#1">Concept</a>
                    <a href="#2">Code</a>

                </aside>
            </div>
            
            <main className="subsubsection-content">
    <div className="titles"><h1>ProphetNET</h1></div>

    <section id="1" className="code-cleaned">
    <h2>Concept</h2>
        <p className="subsubsection-paragraph">
        ProphetNet is a language model developed by Microsoft, Sichuan University, and the University of Science and Technology of China. Unlike traditional autoregressive 
        models that predict the next token in a sequence one at a time, ProphetNet introduces a novel approach called future n-gram prediction. This technique allows the model to predict several
         future tokens simultaneously which captures a broader context and facilitates a deeper understanding of the text. The model achieves this through a unique architecture that includes an n-stream
          self-attention mechanism; I won't get too much into this but you can read the paper below to get some more understanding. </p>

            <p className="subsubsection-paragraph">
            Despite its advantages, ProphetNet's complexity and the resource-intensive nature of its n-stream self-attention mechanism present challenges, particularly in terms of computational 
            requirements and training time. Nonetheless, the model's versatility and its excellent performance on several benchmarks make it a valuable tool for researchers and data scientists
             working in NLP.
            </p>

            <p className="subsubsection-paragraph">
            You can read more about this approach in: "ProphetNet: Predicting Future N-gram for Sequence-to-Sequence Pre-training" by Weizhen Qi, Yeyun Gong, Yu Yan, Can Xu, Bolun Yao, Bartuer Zhou, 
            Biao Cheng, Daxin Jiang, Jiusheng Chen, Ruofei Zhang, Houqiang Li, and Nan Duan 
            </p>

            </section>

            <section id="2" className="code-cleaned">
            <h2>In Code</h2>
            <p className="subsubsection-paragraph">
            
<SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
{`from transformers import ProphetNetTokenizer, ProphetNetForConditionalGeneration

# Load the pre-trained ProphetNet model and tokenizer
tokenizer = ProphetNetTokenizer.from_pretrained('microsoft/prophetnet-large-uncased')
model = ProphetNetForConditionalGeneration.from_pretrained('microsoft/prophetnet-large-uncased')

# Define your input text
input_text = "The future of AI in healthcare is"

# Encode the input text
input_tokens = tokenizer.encode(input_text, return_tensors="pt")

# Generate predictions (output tokens) from the model
output_tokens = model.generate(input_tokens, max_length=50, num_beams=5, early_stopping=True)

# Decode the output tokens to a readable string
generated_text = tokenizer.decode(output_tokens[0], skip_special_tokens=True)

print("Generated Text:", generated_text)
`}
</SyntaxHighlighter>
</p>
        
    </section>

    <div className="subsubsection-navigation">
        <Link to="/frontier">← Frontier</Link>
        <Link to="/frontier/reformers">Reformers →</Link>
    </div>
</main>
            
            <Footer />
        </div>
    );
}



export default Prophet;
