import React from 'react';
import '../../styles/subsection.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import 'katex/dist/katex.min.css';
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function UnifiedQA() {
    return (
        <div className="subsubsection-container">
            <Header />
            <div class="side-nav-container">
                <aside className="subsubsection-side-nav">
                    <a href="#1">Concept</a>
                    <a href="#2">Code</a>
                </aside>
            </div>
            
            <main className="subsubsection-content">
            <div className="titles"><h1>UnifiedQA</h1></div>

            <section id="1" className="code-cleaned">
                <h2>Concept</h2>
                    <p className="subsubsection-paragraph">
                    Empty</p>

                        <p className="subsubsection-paragraph">
                        Empty
                        </p>

                        <p className="subsubsection-paragraph">
                        Empty
                        </p>

                        </section>

                        <section id="2" className="code-cleaned">
                        <h2>In Code</h2>
                        <p className="subsubsection-paragraph">
                            Here's a Python example that demonstrates how UnifiedQA can be used to answer questions. We'll use the Hugging Face Transformers library, which provides easy 
                            access to pre-trained models like UnifiedQA:
                        
<SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
{`from transformers import pipeline

# Load UnifiedQA model
qa_pipeline = pipeline("question-answering", model="etalab/unifiedqa-turbo")

# Ask a question
question = "What is the capital of France?"
answer = qa_pipeline(question=question)

print(answer)
`}
</SyntaxHighlighter>
</p>
                    
                
            </section>

            <div className="subsubsection-navigation">
                <Link to="/frontier/electra">← ELECTRA</Link>
                <Link to="/frontier/ctrl">CTRL →</Link>
            </div>
        </main>
            
            <Footer />
        </div>
    );
}

export default UnifiedQA;
