import React from 'react';
import { Link } from 'react-router-dom';
import headerImg from '../../media/header_img.png';
import '../../styles/header.css';

function Header() {
    return (
        <header className="header">
            <Link to="/">
                <img src={headerImg} alt="Logo" className="logo"/>
            </Link>
        </header>
    );
}

export default Header;
