import React from 'react';
import '../../styles/subsection.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';
import { Link } from 'react-router-dom';
import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';

function Python() {
    return (
        <div className="subsubsection-container">
            <Header />
            <div class="side-nav-container">
                <aside className="subsubsection-side-nav">
                    <a href="#python">Python Basics</a>
                    <a href="#core-libraries">Core Libraries</a>
                    <a href="#tools">Additional Tools</a>
                </aside>
            </div>
            
            <main className="subsubsection-content">
                
                <div className="titles"><h1>Python & NLP Libraries</h1></div>
                <section id="python" className="code-cleaned">
                <h2>Python Foundations</h2>
                <p className="subsubsection-paragraph">
                    Python is a versatile, high-level programming language created by Guido Van Rossum in 1991. Its syntax is clean and expressive, making it an excellent choice for beginners and a
                     powerful tool for seasoned developers. The Python Foundations section will just provide a quick general overview of Python along with some important libraries for NLP.
                </p>

               

                <h4>Data Structures</h4>
                <p className="subsubsection-paragraph">
                    Python offers a variety of built-in data structures, such as lists, dictionaries, sets, and tuples. These structures are designed to be flexible and provide a foundation for 
                    organizing, storing, and managing data.
                
                    <SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
{`# Example of a list in Python 
my_list = ["1 billion", "Hello", 3.141414]`}
</SyntaxHighlighter>
                    </p>     
                

                <h4>Control Structures</h4>
                <p className="subsubsection-paragraph">
                    Control structures in Python include if-else conditions, for loops, and while loops (and others). These constructs allow for the execution of code blocks based on conditions and are
                     essential for creating logic in your programs. 
                    
                    <SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
{`# Example of an if-else condition
x = 10
if x > 5:
    print("x is less than 5; joking haha")
else:
    print("x is 5 or less")`}
</SyntaxHighlighter>
                    </p>   

                
                
                <h4>Functions</h4>
                <p className="subsubsection-paragraph">
                    Functions in Python are defined using the 'def' keyword and are used to encapsulate reusable blocks of code. Functions can take arguments and return values. This is how you'll 
                    define a lot of models as you work through this website.
                
                    <SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
{`# Example of a function in Python
def greet(name):
    return "Hello " + name

print(greet("LOL"))`}
</SyntaxHighlighter>
                    </p>


                
                
                <h4>Data I/O</h4>
                <p className="subsubsection-paragraph">
                    Data Input/Output (I/O) in Python involves reading from and writing to files on disk. Python's 'open()' function can be used but lots of people just use Pandas for most things data 
                    science related.
              
                    <SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
{`# Example of file I/O in Python
with open('example.txt', 'w') as file:
    file.write("Hello, welcome to learning NLP nerd")`}
</SyntaxHighlighter>
                    </p>  


                

                <h4>Data Operations</h4>
                <p className="subsubsection-paragraph">
                    Python's data operations include the manipulation of strings, numbers, and data structures. These operations can perform tasks like searching, sorting, and converting data types.
                    
                    <SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
{`# Example of data operations in Python
numbers = [3, 1, 4, 1, 5, 9, 2, 6, 5] 
numbers.sort()
print(numbers)`}
</SyntaxHighlighter>

All this stuff should be super obvious and if not, you should take a course on Python before venturing forward.
                    </p>            


                
            </section>

                
            <section id="core-libraries" className="code-cleaned">
                <h2>Core NLP Libraries</h2>
                <p className="subsubsection-paragraph">
                    NLP libraries provide the means to process and analyze large volumes of text data. They 
                    enable the implementation of various NLP tasks such as tokenization, parsing, semantic analysis, and sentiment analysis. Below is an overview of some of the core NLP libraries.
                </p>

                <h4>NLTK</h4>
                <p className="subsubsection-paragraph">
                    NLTK, or Natural Language Toolkit, is a good library for building Python programs to work with human language data. It provides easy-to-use interfaces to over 50 corpora 
                    and lexical resources such as WordNet, along with a suite of text processing libraries for classification, tokenization, stemming, tagging, parsing, and semantic reasoning.
                    
                    <SyntaxHighlighter language="python" style={docco}  className="codeStyle_small">
{`# Example of using NLTK for tokenization
import nltk
nltk.download('punkt')
from nltk.tokenize import word_tokenize

text = "Hello there, what's up?"
tokens = word_tokenize(text)
print(tokens)`}
</SyntaxHighlighter>
                    </p>

                

                <h4>spaCy</h4>
                <p className="subsubsection-paragraph">
                    spaCy is a free, open-source library for NLP in Python. It's designed specifically for production use and helps you build applications that
                     process large volumes of text. It can be used to build information extraction or natural language understanding systems and is also widely used for tasks such as
                      named entity recognition and part-of-speech tagging.
                    
                    <SyntaxHighlighter language="python" style={docco}  className="codeStyle_small">
{`# Example of using spaCy for named entity recognition
import spacy
nlp = spacy.load('en_core_web_sm')

doc = nlp("Apple is looking at buying my startup for $1 trillion")
for ent in doc.ents:
    print(ent.text, ent.start_char, ent.end_char, ent.label_)`}
</SyntaxHighlighter>
                    </p>

                

                <h4>TextBlob</h4>
                <p className="subsubsection-paragraph">
                    TextBlob is a Python library for processing textual data. It provides a simple API for diving into common natural language processing tasks such as part-of-speech tagging, noun 
                    phrase extraction, sentiment analysis, classification, translation, and more. TextBlob is particularly user-friendly, making it an excellent choice for beginners in NLP.
                    
                    <SyntaxHighlighter language="python" style={docco}  className="codeStyle_small">
{`# Example of using TextBlob for sentiment analysis
from textblob import TextBlob

feedback = "I love this product, it's absolutely amazing!"
blob = TextBlob(feedback)
print(blob.sentiment)`}
</SyntaxHighlighter>
                    </p>

                

                <h4>gensim</h4>
                <p className="subsubsection-paragraph">
                    gensim is a robust open-source vector space modeling and topic modeling toolkit implemented in Python. It uses NumPy, SciPy, and optionally Cython for performance. gensim is designed
                     to handle large text collections using data streaming and incremental online algorithms, which differentiates it from most other machine learning libraries that require all input to
                      reside in memory.

                                                        <SyntaxHighlighter language="python" style={docco}  className="codeStyle_small">
{`# Example of using gensim for topic modeling
from gensim import corpora, models

texts = [['human', 'interface', 'computer'],
        ['survey', 'user', 'computer', 'system', 'response', 'time']]
dictionary = corpora.Dictionary(texts)
corpus = [dictionary.doc2bow(text) for text in texts]

ldamodel = models.ldamodel.LdaModel(corpus, num_topics=2, id2word=dictionary, passes=20)
print(ldamodel.print_topics(num_topics=2, num_words=4))`}
</SyntaxHighlighter>
                    </p>



                

                <h4>Scikit-learn</h4>
                <p className="subsubsection-paragraph">
                    Scikit-learn is a machine learning library for the Python programming language. It features various algorithms such as support vector machines, random forests, and k-neighbours, 
                    and also supports Python numerical and scientific libraries like NumPy and SciPy. In NLP, it's commonly used for feature extraction, building classifiers, clustering text documents, 
                    and more.
                    
                    <SyntaxHighlighter language="python" style={docco}  className="codeStyle_small">
{`# Example of using Scikit-learn for text classification
from sklearn.feature_extraction.text import CountVectorizer
from sklearn.naive_bayes import MultinomialNB
from sklearn.pipeline import make_pipeline

# Sample text for model training
train_texts = ["I love this movie", "This movie is terrible"]
train_labels = [1, 0]

# Creating a model pipeline
model = make_pipeline(CountVectorizer(), MultinomialNB())

# Training the model
model.fit(train_texts, train_labels)

# Making a prediction
print(model.predict(["The acting was great"]))`}
        </SyntaxHighlighter>
                    </p>    

                    
                    <h4>Huggingface</h4>
                    <p className="subsubsection-paragraph">
                        Huggingface's Transformers library offers a comprehensive suite of pre-trained models that can be fine-tuned for tasks like text classification, question answering, and more.
                    
                        <SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
{`from transformers import pipeline

# Initialize a sentiment analysis pipeline
classifier = pipeline('sentiment-analysis')

# Classify the sentiment of a sentence
result = classifier('I love using transformers for NLP tasks!')[0]
print(f"Label: {result['label']}, Score: {result['score']:.4f}")`}
</SyntaxHighlighter>
                    </p>


                    <h4>Tesseract</h4>
                    <p className="subsubsection-paragraph">
                        Tesseract OCR is an open-source OCR (Optical Character Recognition) engine used to recognize text from images. 
                        Integrated with Python through wrappers like pytesseract, it enables automated text 
                        extraction from a variety of image formats.
                    
                        <SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
        {`import pytesseract
from PIL import Image

# Open an image file
image = Image.open('image_with_text.jpg')

# Use Tesseract to extract text
extracted_text = pytesseract.image_to_string(image)

print(extracted_text)`}
</SyntaxHighlighter>
                    </p>

</section>


<section id="tools" className="code-cleaned">
    <h2>Other Tools</h2>
    <p className="subsubsection-paragraph">
        Besides dedicated NLP libraries, various other tools are essential for text extraction, web scraping, and pattern matching that are fundamental in NLP tasks.
    </p>

    <h4>Regex</h4>
    <p className="subsubsection-paragraph">
        Regular expressions (regex) are used to identify patterns within text, which is vital for tasks such as tokenization, search, and text cleanup.
    
        <SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
        {`import re

# Sample regex to match email addresses
email_pattern = r'[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+'

# Example text
text = "Contact us at hahalol@example.com"

# Find all matches in the text
emails = re.findall(email_pattern, text)

print(emails)`}
    </SyntaxHighlighter>
    </p>


    <h4>Beautiful Soup</h4>
    <p className="subsubsection-paragraph">
        Beautiful Soup is a Python library for pulling data out of HTML and XML files. It provides tools for web scraping which can be used to collect data from websites.
    
        <SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
{`from bs4 import BeautifulSoup
import requests

# Fetching HTML content from a web page
response = requests.get('http://example.com')
html_content = response.text

# Parsing the HTML content with Beautiful Soup
soup = BeautifulSoup(html_content, 'html.parser')

# Extracting all hyperlinks
for link in soup.find_all('a'):
    print(link.get('href'))`}
    </SyntaxHighlighter>
    </p>


    <h4>Scrapy</h4>
    <p className="subsubsection-paragraph">
        Scrapy is an open-source and collaborative web crawling framework for Python. It's designed for scraping websites and extracting structured data which can be used for a wide range of purposes, from data mining to information processing or historical archival.
    
        <SyntaxHighlighter language="python" style={docco} className="codeStyle_small">
{`import scrapy

class QuotesSpider(scrapy.Spider):
name = "quotes"

def start_requests(self):
    urls = [
        'http://quotes.toscrape.com/page/1/',
        'http://quotes.toscrape.com/page/2/',
    ]
    for url in urls:
        yield scrapy.Request(url=url, callback=self.parse)

def parse(self, response):
    page = response.url.split("/")[-2]
    filename = f'quotes-{page}.html'
    with open(filename, 'wb') as f:
        f.write(response.body)
    self.log(f'Saved file {filename}')`}
</SyntaxHighlighter>
    </p>

</section>

                
                
                <div className="subsubsection-navigation">
                    <Link to="/foundations">← Foundations</Link>
                    <Link to="/foundations/linalg">Linear Algebra →</Link>
                </div>
            </main>
            
            <Footer />
        </div>
    );
}

export default Python;
