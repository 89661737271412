import React from 'react';
import '../../styles/section.css';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

function ExistingModels() {
    return (
        <div className="section-container">
            <Header />
            <main className="section-content">
                <p className="section-intro-paragraph">Learn about popular models here</p>
                <div className="subsection-links">
                    <a href="/existingmodels/bert">BERT & Its Variants</a>
                    <a href="/existingmodels/generativepretrained">Generative Pre-trained Transformers</a>
                    <a href="/existingmodels/advancedexisting">Alternatives & Advanced Models</a>
                    <a href="/existingmodels/zeroshot">Zero-shot, Few-shot, Many-shot</a>
                    <a href="/existingmodels/huggingface">Hugging Face</a>
                </div>
            </main>
            <Footer />
        </div>
    );
}

export default ExistingModels;